<template>
  <main>
    <b-overlay :show="loading">
      <!-- <form-filter-export :type_export="['excel']" @onExport="exportSales">
      <template #default>
        <b-form-group v-if="!isSales" label="Sales " class="mr-2">
          <v-select :options="salesOptions" v-model="dataExport.sales_id" label="text" :reduce="option => option.value"></v-select>
        </b-form-group>
        <b-form-group label="Konsumen (opsional)">
          <v-select :options="konsumenOptions" v-model="dataExport.konsumen_id" label="text" :reduce="option => option.value"></v-select>
        </b-form-group>
      </template>
    </form-filter-export> -->
      <b-card>
        <b-row class="align-items-center">
          <b-col sm="12" md="6" class="my-1">
            <!-- <section v-if="allowCreate()" class="d-flex align-items-center"> -->
            <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              @click.prevent="$router.push('/transaksi-penjualan-toko')"
            >
              <feather-icon icon="ShoppingBagIcon" class="mr-50" />
              Toko
            </b-button> -->
            <!-- </section> -->
          </b-col>

          <b-col sm="12" md="6" class="d-flex justify-content-end">
            <h2 class="text-success text-right">
              Data Contoh
              <!-- Total Uang Masuk:
              <strong>Rp {{ formatRupiah(this.masuk) }}</strong> -->
            </h2>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="2" lg="2" md="4" sm="4" class="">
            <b-form-group
              class="mb-0"
              :label="$t('Per page')"
              label-for="perPageSelect"
            >
              <b-form-select
                id="perPageSelect"
                size="sm"
                v-model="perPage"
                :options="pageOptions"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="4" sm="8" class="">
            <b-form-group :label="$t('Sort')"   label-for="sortBySelect"
              class="mb-0">
              <b-input-group >
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                  <template v-slot:first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc"  :disabled="!sortBy" class="w-25">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col> -->
          <b-col xl="10" lg="10" md="8" sm="8" class="">
            <b-form-group :label="$t('Filter')" label-for="filterInput" class="mb-0">
              <b-input-group>
                <b-form-input
                  id="filterInput"
                  size="sm"
                  v-model="filter"
                  type="search"
                  placeholder="Tekan enter untuk cari"
                  @keydown.enter.prevent="getTransaksi()"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    size="sm"
                    @click="
                      filter = '';
                      getTransaksi();
                    "
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isFinance || isOwner || isCEO">
          <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
            <b-form-group
              label="Status Bayar"
              label-for="status_bayar"
              class="mb-0"
            >
              <b-input-group>
                <b-form-select
                  size="sm"
                  id="status_bayar"
                  v-model="status_bayar"
                  :options="optstatus_bayar"
                  class=""
                  @input="getTransaksi()"
                >
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- Semua --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
            <b-form-group label="Status" label-for="statuskirim" class="mb-0">
              <b-input-group>
                <b-form-select
                  size="sm"
                  id="statuskirim"
                  v-model="statuskirim"
                  :options="optstatuskirim"
                  class=""
                  @input="getTransaksi()"
                >
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- Semua --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
            <b-form-group
              label="Daftar yang sudah masuk ke akun dan kas"
              label-for="is_accounted"
              class="mb-0"
            >
              <b-input-group>
                <b-form-select
                  size="sm"
                  id="is_accounted"
                  v-model="is_accounted"
                  :options="optis_accounted"
                  class=""
                  @input="getTransaksi()"
                >
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- Semua --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
            <b-form-group
              label="Pilih Affiliator"
              label-for="is_member"
              class="mb-0"
            >
              <b-input-group>
                <b-form-select
                  size="sm"
                  id="is_member"
                  v-model="is_member"
                  :options="optis_member"
                  class=""
                  @input="getTransaksi()"
                >
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- Semua --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isCabang || isMarketing">
          <b-col class="mt-1" xl="4" lg="4" md="4" sm="12">
            <b-form-group
              label="Status Bayar"
              label-for="status_bayar"
              class="mb-0"
            >
              <b-input-group>
                <b-form-select
                  size="sm"
                  id="status_bayar"
                  v-model="status_bayar"
                  :options="optstatus_bayar"
                  class=""
                  @input="getTransaksi()"
                >
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- Semua --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="mt-1" xl="4" lg="4" md="4" sm="12">
            <b-form-group label="Status" label-for="statuskirim" class="mb-0">
              <b-input-group>
                <b-form-select
                  size="sm"
                  id="statuskirim"
                  v-model="statuskirim"
                  :options="optstatuskirim"
                  class=""
                  @input="getTransaksi()"
                >
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- Semua --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="mt-1" xl="4" lg="4" md="4" sm="12">
            <b-form-group label="Status" label-for="typeTransaksi" class="mb-0">
              <b-input-group>
                <b-form-select
                  size="sm"
                  id="typeTransaksi"
                  v-model="typeTransaksi"
                  :options="opttypetransaksi"
                  class=""
                  @input="getTransaksi()"
                >
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- Semua --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mt-1">
            <b-table
              small
              hover
              responsive
              :per-page="perPage"
              :items="list.data.content"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :fields="fieldsginee"
              >
              <template #cell(no)="{ index }">
                {{ index + 1 }}
              </template>
              <template #cell(productinfo)="{ item }">
                <b-alert variant="primary" show>{{item.externalOrderSN}}</b-alert>
				<template v-for=" hem in item.orderItems" >
                <b-card bg-variant="transparent" no-body class="overflow-hidden"  >
                  <b-row no-gutters>
      <b-col md="3">
        <b-card-img style="width: 80px;" :src="hem.productImage" alt="Image" class="rounded-0"></b-card-img>
      </b-col>
      <b-col md="9">
        <!-- <b-card-body :title="hem.productName"> -->
          <b-card-text>
            <span :title="hem.productName">{{truncateString(hem.productName, 20)}}</span><br>
            {{hem.variationName}}<br>
            {{hem.inventorySku}}<br>
            {{ formatRupiah(hem.actualPrice) }} x {{hem.quantity}}
          </b-card-text>
        <!-- </b-card-body> -->
      </b-col>
    </b-row>
                  <!-- <b-img :src="hem.productImage" :alt="hem.id" style="width: 50px"> -->
                  <!-- </b-img> -->
                  <!-- {{hem.variationName}} -->
                </b-card>
			</template>
              </template>
              <template #cell(outbondorder)="{ item }">

              </template>
              <template #cell(status_bayar)="{ item }">
                <b-badge v-if="item.status_bayar == 'lunas'" variant="success"
                  >LUNAS</b-badge
                >
                <b-badge v-else variant="danger">BELUM LUNAS</b-badge>
              </template>
              <template #cell(payment_option)="{ item }">
                <b-badge
                  v-if="item.payment_option == 'manual_transfer'"
                  variant="warning"
                  >Manual Transfer</b-badge
                >
                <b-badge
                  v-else-if="item.payment_option == 'pg_midtrans'"
                  variant="success"
                  >Otomatis</b-badge
                >
                <b-badge v-else variant="danger">COD</b-badge>
              </template>
              <template #cell(status)="{ item }">
                <b-badge v-if="item.status == 'selesai'" variant="success"
                  >SELESAI</b-badge
                >
                <b-badge v-else-if="item.status == 'diproses'" variant="warning"
                  >DIPROSES</b-badge
                >
                <b-badge v-else-if="item.status == 'dikirim'" variant="primary"
                  >DIKIRIM</b-badge
                >
                <b-badge v-else-if="item.status == 'diterima'" variant="info"
                  >DITERIMA</b-badge
                >
                <b-badge
                  v-else-if="item.status == 'dibatalkan'"
                  variant="danger"
                  >DIBATALKAN</b-badge
                >
                <b-badge v-else variant="secondary">PENDING</b-badge>
              </template>
              <template #cell(rincian)="{ item }">
                {{ item.rincian[0] ? item.rincian[0].nama_barang : "-" }}
              </template>
              <template #cell(waktu_transaksi)="{ item }">
                <strong>{{ humanDateTime(item.waktu_transaksi) }}</strong>
              </template>
              <template #cell(uang_masuk)="{ item }">
                <strong>Rp {{ formatRupiah(item.uang_masuk) }}</strong>
              </template>
              <template #cell(total_bayar)="{ item }">
                <strong>Rp {{ formatRupiah(item.total_bayar) }}</strong>
              </template>
              <template #cell(transaction_type)="{ item }">
                <strong>
                  {{
                    item.transaction_type == "jasa" ? "Jasa" : "Barang"
                  }}</strong
                >
              </template>

              <template #cell(action)="{ item }">
                <b-button
                  size="sm"
                  variant="warning"
                  @click.prevent="
                    $router.push(`/transaksi-penjualan/detail/${item.id}`)
                  "
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button
                  v-if="
                    item.transaction_type == 'jasa' &&
                    item.status != 'dibatalkan'
                  "
                  size="sm"
                  variant="outline-primary"
                  @click.prevent="modalTerapis(item)"
                  v-b-tooltip.hover.top="'Pilih Terapis'"
                >
                  <feather-icon icon="UserIcon" />
                </b-button>
                <b-button
                  v-if="
                    item.status == 0 &&
                    allowUpdate() &&
                    item.tak_tertagih == 0 &&
                    item.bayar == 0
                  "
                  size="sm"
                  variant="outline-warning"
                  @click.prevent="
                    $router.push(`/transaksi-penjualan/edit/${item.id}`)
                  "
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </template>
              <template #cell(actionFinance)="{ item }">
                <b-button
                  size="sm"
                  variant="warning"
                  @click.prevent="
                    $router.push(`/transaksi-penjualan/detail/${item.id}`)
                  "
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button
                  title="Konfirmasi Pembayaran Manual"
                  v-if="item.status_bayar != 'lunas'"
                  size="sm"
                  variant="primary"
                  @click="aksiKonfirmasiawal(item)"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
                <b-button
                  title="Cek Pembayaran Otomatis"
                  v-if="
                    item.status_bayar != 'lunas' &&
                    item.payment_option == 'pg_midtrans'
                  "
                  size="sm"
                  variant="info"
                  @click="aksiKonfirmasipg(item)"
                >
                  <feather-icon icon="DollarSignIcon" />
                </b-button>
                <!-- <b-button title="Konfirmasi Pembayaran" v-if="item.status_bayar != 'lunas' && item.status != 'dibatalkan'" size="sm" variant="primary" @click="aksiKonfirmasi(item)">
                  <feather-icon icon="CheckIcon" />
                </b-button> -->
                <b-modal
                  hide-backdrop
                  no-close-on-esc
                  no-close-on-backdrop
                  v-model="showKonfirmasi"
                  id="modal-konfirmasi"
                  ok-variant="secondary"
                  centered
                  :title="'Konfirmasi Pembayaran ' + formkonfirmasi.no_invoice"
                >
                  <b-row>
                    <!-- {{ this.konfirmasi != null ? this.konfirmasi : '-' }} -->
                    <b-col xl="12" lg="12" md="12" sm="12">
                      <!-- <b-card> -->
                      <!-- <validation-observer ref="vkonfirmasi">
                        <b-form>
                            <b-row>
                              <b-col sm="12" md="12">
                                <b-form-group label="Jenis Transaksi">
                                  <b-form-select
                                    v-model="transactionType"
                                    :options="[
                                      { value: 1, text: 'Debit' },
                                      { value: 2, text: 'Kredit' },
                                    ]"
                                    id="v-transaction_type"
                                    name="transaction_type"
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col sm="12" md="12" v-if="transactionType">
                                <b-form-group label="Pilih Akun">
                                  <validation-provider #default="{ errors }" rules="required" name="id_akun">
                                    <b-form-select v-model="formkonfirmasi.id_akun" :options="id_akun" id="v-id_akun" name="id_akun" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col sm="12" md="12">
                                <b-form-group label="Pilih Kas">
                                  <validation-provider #default="{ errors }" rules="required" name="id_kas">
                                    <b-form-select v-model="formkonfirmasi.id_kas" :options="id_kas" id="v-id_kas" name="id_kas" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-form> -->
                      <!-- <b-button block>Lunas</b-button> -->
                      <!-- </validation-observer> -->
                      <b-button
                        variant="primary"
                        @click.prevent="submitkonfirmasi"
                        block
                        class="mb-2"
                      >
                        Konfirmasi Lunas
                      </b-button>
                      <!-- </b-card> -->
                      <!-- </b-col>
                    <b-col xl="6" lg="6" md="12" sm="12"> -->
                      <!-- <b-card border-variant="primary">
                        
                      </b-card> -->
                      <!-- </b-col>
                    <b-col xl="6" lg="6" md="12" sm="12"> -->
                      <!-- <b-card> -->
                      <b-overlay :show="loadingfoto">
                        <h3 align="center">Bukti Transfer</h3>
                        <template v-if="formkonfirmasi.bukti_tf">
                          <b-img
                            :src="apiFile + formkonfirmasi.bukti_tf.file"
                            alt=""
                            fluid-grow
                          />
                        </template>
                        <template v-else>
                          <center>
                            <h3 class="text-danger">Belum diupload</h3>
                          </center>
                        </template>
                      </b-overlay>
                      <!-- </b-card> -->
                    </b-col>
                  </b-row>
                  <template #modal-footer>
                    <section
                      class="d-flex justify-content-end align-items-center"
                    >
                      <b-button
                        size="sm"
                        variant="danger"
                        @click="
                          showKonfirmasi = false;
                          getTransaksi();
                        "
                      >
                        Keluar
                      </b-button>
                    </section>
                  </template>
                </b-modal>
              </template>
            </b-table>
          </b-col>

          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>

      <b-modal
        title="Pilih Terapis"
        centered
        size="lg"
        hide-footer
        v-model="showModalTerapis"
      >
        <b-overlay :show="loadingTerapis">
          <b-table
            small
            :items="listTerapis"
            :fields="fieldsTerapis"
            responsive
          >
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:cell(nama_lengkap)="row">
              {{ row.item.nama_lengkap }}
            </template>
            <template v-slot:cell(no_hp)="row">
              {{ row.item.no_hp }}
            </template>
            <template v-slot:cell(alamat)="row">
              {{ row.item.alamat }}
            </template>
            <template v-slot:cell(on_progress_orders)="row">
              {{ row.item.on_progress_orders.length }}
            </template>
            <template v-slot:cell(completed_orders)="row">
              {{ row.item.completed_orders.length }}
            </template>
            <template v-slot:cell(action)="row">
              <b-button
                size="sm"
                variant="primary"
                @click="pilihTerapis(row.item)"
                class="statistics-item-action mt-2"
              >
                Pilih
              </b-button>
            </template>
          </b-table>
        </b-overlay>
      </b-modal>
    </b-overlay>
  </main>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormFilterExport from "@core/components/form-filter-export/FormFilterExport.vue";
import {
  BAlert,
  BTable,
  BCard,BCardBody,BCardImg,BCardText,BCardTitle,
  VBTooltip,
  BImg,
  BForm,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import mixin from "./mixin";
// const maxLength = 20;
// const truncatedString = truncateString(originalString, maxLength);

export default {
  mixins: [mixin],
  components: {
    BAlert,
    ToastificationContent,
    FormFilterExport,
    VBTooltip,
    BModal,
    BDropdown,
    BDropdownItem,
    BTable,
    BCard,BCardBody,BCardImg,BCardText,BCardTitle,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BImg,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      showModalEdit: false,
      showModalAdd: false,
      showModalTerapis: false,
      loadingTerapis: false,
      itemTransaksiTerapis: {},
      id: null,
      form: {
        blok: null,
        gudang_id: null,
      },
      dataGudang: [],
      listTerapis: [],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      dataExport: {
        konsumen_id: null,
        sales_id: null,
      },
      fieldsginee: [
        {
          key: "no",
          label: "No",
        },
        { key: "productinfo", label: "Product Info", sortable: true },
        { key: "outbondorder", label: "Outbond Order" },
        // { key: "tanggal", label: "Tanggal Transaksi", sortable: true },
        { key: "logistics", label: "Logistics" },
        // { key: "operate", label: "" },
        { key: "action", label: "Operate" },
      ],
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "no_invoice", label: "No. Invoice", sortable: true },
        { key: "nama", label: "Konsumen" },
        // { key: "tanggal", label: "Tanggal Transaksi", sortable: true },
        { key: "status_bayar", label: "Status Bayar" },
        { key: "status", label: "Status Pengiriman" },
        { key: "action", label: "#" },
      ],
      fieldsTerapis: [
        {
          key: "no",
          label: "No",
        },
        { key: "nama_lengkap", label: "Nama Lengkap", sortable: true },
        { key: "no_hp", label: "No. HP" },
        { key: "alamat", label: "Alamat" },
        { key: "on_progress_orders", label: "Sedang Orderan" },
        { key: "completed_orders", label: "Selesai Orderan" },
        { key: "action", label: "#" },
      ],
      items: [],
      swalOptions: {
        title: "Pindahkan Stok Retur ke Mobil?",
        text: `Stok Barang Retur akan kembali ke Stok Mobil Anda`,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Retur Barang",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      },
      salesOptions: [],
      konsumenOptions: [],
      loading: false,
      loadingfoto: false,
      showKonfirmasi: false,
      formkonfirmasi: {
        no_invoice: null,
        bukti_tf: {
          file: null,
        },
      },
      konfirmasi: null,
      id_akun: [],
      id_kas: [],
      transactionType: null,
      debitAkun: [],
      kreditAkun: [],
      foto: null,
      status_bayar: null,
      optstatus_bayar: [
        { text: "Lunas", value: "lunas" },
        { text: "Belum Lunas", value: "belum_lunas" },
      ],
      statuskirim: null,
      optstatuskirim: [
        { value: "selesai", text: "Selesai" },
        { value: "diproses", text: "Diproses" },
        { value: "dikirim", text: "Dikirim" },
        { value: "dibatalkan", text: "Dibatalkan" },
        { value: "pending", text: "Pending" },
      ],
      typeTransaksi: null,
      opttypetransaksi: [
        { text: "Member", value: "member" },
        { text: "Non Member", value: "non-member" },
      ],
      member_id: null,
      is_accounted: null,
      optis_accounted: [
        { text: "Ya", value: 1 },
        // { text: 'Tidak', value: != 1 },
      ],
      masuk: null,
      optis_member: null,
      is_member: null,
      list:{
	"code": "SUCCESS",
	"message": "OK",
	"data": {
		"content": [
			{
				"id": "SO66E1354646E0FB00010237A6",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NC0VDU24",
				"externalOrderSN": "240911NC0VDU24",
				"orderStatus": "PENDING_PAYMENT",
				"externalOrderStatus": "UNPAID",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 77940,
				"totalQuantity": 1,
				"customerName": "I******)",
				"customerMobile": "******03",
				"recipientName": "I******)",
				"logisticsProviderName": "Reguler (Cashless)",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": "Sri Lussy anggraeni",
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": false,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E1354646E0FB00010237A9",
						"externalOrderItemId": "240911NC0VDU24-0",
						"externalVariationId": "28907846554",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Akame (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qul1-lgi7sicoqtx7bf_tn",
						"quantity": 1,
						"actualPrice": 105000,
						"sku": "F-AKAME-M",
						"inventorySku": "F-AKAME-M",
						"inventoryId": "MV6528EC61CFF47E0001D97D38",
						"variationName": "MIST",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "Reguler (Cashless)",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833750951077597184",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:14:30Z",
				"externalUpdateDatetime": "2024-09-11T06:14:30Z",
				"promiseShipBefore": null,
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:14:31Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E1351946E0FB0001023131",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NBYHH169",
				"externalOrderSN": "240911NBYHH169",
				"orderStatus": "PAID",
				"externalOrderStatus": "PENDING",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "COD",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 82183,
				"totalQuantity": 1,
				"customerName": "H******a",
				"customerMobile": "******00",
				"recipientName": "H******a",
				"logisticsProviderName": "Hemat",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": false,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E1351946E0FB0001023134",
						"externalOrderItemId": "240911NBYHH169-0",
						"externalVariationId": "79020556791",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Anya (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qul0-lgi8p51lki6314_tn",
						"quantity": 1,
						"actualPrice": 109900,
						"sku": "F-ANYA-CR",
						"inventorySku": "F-ANYA-CR",
						"inventoryId": "MV6528EC61CFF47E0001D97DC8",
						"variationName": "CRYSTAL",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833750762833014784",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:13:45Z",
				"externalUpdateDatetime": "2024-09-11T06:13:45Z",
				"promiseShipBefore": null,
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:13:46Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E134C846E0FB0001E32F1B",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE5F4B46E0FB00015113FE",
				"shopName": "HeyKama",
				"externalOrderId": "1941734333",
				"externalOrderSN": "INV/20240911/MPL/4149664055",
				"orderStatus": "PAID",
				"externalOrderStatus": "220",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "TOKOPEDIA_ID",
				"channelName": "Tokopedia",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "0",
				"sellerTotalAmount": 299200,
				"totalQuantity": 2,
				"customerName": "Naomi Nameera",
				"customerMobile": "*********3257",
				"recipientName": "Naomi NP",
				"logisticsProviderName": "J&T",
				"logisticsTrackingNumber": "",
				"deliveryType": "Reguler",
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": null,
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E134C846E0FB0001E32F22",
						"externalOrderItemId": "4149664056",
						"externalVariationId": "5254510847",
						"externalStatus": null,
						"productName": "LENSA ANTIRADIASI + BLUERAY BR (SPH/CYL) - -0.25 s/d -3.00",
						"productImage": "https://images.tokopedia.net/img/cache/100-square/VqbcmM/2023/5/8/dddcc3e8-cd49-4298-93e0-e3e930b83c55.jpg",
						"quantity": 1,
						"actualPrice": 166500,
						"sku": "LNS-BR-MIN-0.25",
						"inventorySku": "LNS-BR-MIN-0.25",
						"inventoryId": "MV6528EC61CFF47E0001D97CBB",
						"variationName": "-0.25 s/d -3.00",
						"fulfillBy": "0",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "J&T",
						"logisticsTrackingNumber": "",
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": "Kanan: -0.5 Kiri: -0.5 . Blueray",
						"outboundOrderNo": "So-1833750421789970432",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "9890311",
								"warehouseName": "Shop Location",
								"warehouseType": "CHANNEL",
								"warehouseCode": null
							},
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E134C846E0FB0001E32F23",
						"externalOrderItemId": "4149664057",
						"externalVariationId": "12078427095",
						"externalStatus": null,
						"productName": "HeyKama - Frame Kacamata Noze ( Cat Eye ) - Mist",
						"productImage": "https://images.tokopedia.net/img/cache/100-square/hDjmkQ/2023/11/10/cd07ead1-eba9-441a-b8cf-b8db3dcc02df.jpg",
						"quantity": 1,
						"actualPrice": 99900,
						"sku": "F-NOZE-M",
						"inventorySku": "F-NOZE-M",
						"inventoryId": "MV6528EC61CFF47E0001D97D9E",
						"variationName": "Mist",
						"fulfillBy": "0",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "J&T",
						"logisticsTrackingNumber": "",
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833750421789970432",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "9890311",
								"warehouseName": "Shop Location",
								"warehouseType": "CHANNEL",
								"warehouseCode": null
							},
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"ACCEPT_ORDER",
					"CANCEL_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"ACCEPT_ORDER",
					"CANCEL_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:12:24Z",
				"externalUpdateDatetime": "2024-09-11T06:12:24Z",
				"promiseShipBefore": "2024-09-12T06:12:24Z",
				"payAtDatetime": "2024-09-11T06:12:24Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:12:24Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E134A3C9E77C00011E549D",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NBV10MTP",
				"externalOrderSN": "240911NBV10MTP",
				"orderStatus": "PAID",
				"externalOrderStatus": "PENDING",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 69280,
				"totalQuantity": 1,
				"customerName": "S******F",
				"customerMobile": "******75",
				"recipientName": "S******F",
				"logisticsProviderName": "Reguler (Cashless)",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": false,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E134A3C9E77C00011E54A0",
						"externalOrderItemId": "240911NBV10MTP-0",
						"externalVariationId": "207063330558",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Tamura",
						"productImage": "https://cf.shopee.co.id/file/sg-11134201-7rbkm-lnjx2l5iarry7c_tn",
						"quantity": 1,
						"actualPrice": 95000,
						"sku": "F-TAMURA-BM",
						"inventorySku": "F-TAMURA-BM",
						"inventoryId": "MV6528EC61CFF47E0001D97CCE",
						"variationName": "BLACK MATTE",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "Reguler (Cashless)",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833750265258565632",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:11:46Z",
				"externalUpdateDatetime": "2024-09-11T06:11:51Z",
				"promiseShipBefore": null,
				"payAtDatetime": "2024-09-11T06:11:51Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:11:52Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E13486C9E77C00011E5081",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NBU5AYM1",
				"externalOrderSN": "240911NBU5AYM1",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 257115,
				"totalQuantity": 2,
				"customerName": "D******i",
				"customerMobile": "******26",
				"recipientName": "D******i",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E13486C9E77C00011E5084",
						"externalOrderItemId": "240911NBU5AYM1-0",
						"externalVariationId": "15032932302",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus Blueray Antiradiasi (SPH/CYL) BR",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 207000,
						"sku": "LNS-BR-MIN-3.25",
						"inventorySku": "LNS-BR-MIN-3.25",
						"inventoryId": "MV6528EC61CFF47E0001D97CBC",
						"variationName": "BLUERAY,MIN -3.25 s/d -6.00",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833750144584237056",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E13486C9E77C00011E5085",
						"externalOrderItemId": "240911NBU5AYM1-1",
						"externalVariationId": "170462447674",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Sora (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qukx-lgiunfrvj01p47_tn",
						"quantity": 1,
						"actualPrice": 89900,
						"sku": "F-SORA-M",
						"inventorySku": "F-SORA-M",
						"inventoryId": "MV6528EC61CFF47E0001D97D91",
						"variationName": "MIST",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833750144584237056",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:11:17Z",
				"externalUpdateDatetime": "2024-09-11T06:11:50Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T06:11:49Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:11:52Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E1340746E0FB0001E31331",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE61CC4CEDFD00016CF12F",
				"shopName": "Hey.Kama",
				"externalOrderId": "577206131994232560",
				"externalOrderSN": "577206131994232560",
				"orderStatus": "PAID",
				"externalOrderStatus": "AWAITING_SHIPMENT",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "TIKTOK_ID",
				"channelName": "TikTok",
				"paymentMethod": "COD",
				"orderType": "NORMAL",
				"fulfillBy": "FULFILLMENTBYSELLER",
				"sellerTotalAmount": 96000,
				"totalQuantity": 1,
				"customerName": "I** a***ta ",
				"customerMobile": "+62 85725",
				"recipientName": "I** a***ta ",
				"logisticsProviderName": "NinjaVan Indonesia",
				"logisticsTrackingNumber": "",
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "Lensa antiradiasi, normal",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E1340746E0FB0001E31334",
						"externalOrderItemId": "577206131994232560-1729443383825172624",
						"externalVariationId": "1729443383825172624",
						"externalStatus": "AWAITING_SHIPMENT",
						"productName": "HeyKama - Frame Kacamata Miki - Kacamata Cat Eye",
						"productImage": "https://p16-oec-sg.ibyteimg.com/tos-alisg-i-aphluv4xwc-sg/1c28cc251c6145f189a06c759cafcdcb~tplv-aphluv4xwc-origin-jpeg.jpeg?from=1413970683",
						"quantity": 1,
						"actualPrice": 95000,
						"sku": "F-MIKI-SB",
						"inventorySku": "F-MIKI-SB",
						"inventoryId": "MV6528EC61CFF47E0001D97D72",
						"variationName": "Silver Black",
						"fulfillBy": "FULFILLMENT_BY_SELLER",
						"packageId": "1154837220532193008",
						"invoiceNumber": null,
						"logisticsProviderName": "NinjaVan Indonesia",
						"logisticsTrackingNumber": "",
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833749612473864192",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "7049609457587816194",
								"warehouseName": "ID Shipping Warehouse",
								"warehouseType": "CHANNEL",
								"warehouseCode": null
							},
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:09:07Z",
				"externalUpdateDatetime": "2024-09-11T06:09:09Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:09:13Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E133F246E0FB0001E31016",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NBPR5DCU",
				"externalOrderSN": "240911NBPR5DCU",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "COD",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 69280,
				"totalQuantity": 1,
				"customerName": "b**a",
				"customerMobile": "******12",
				"recipientName": "b**a",
				"logisticsProviderName": "SPX Hemat",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E133F246E0FB0001E3101C",
						"externalOrderItemId": "240911NBPR5DCU-0",
						"externalVariationId": "26474703953",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Akashi",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qukw-lgi7sicos8o828_tn",
						"quantity": 1,
						"actualPrice": 95000,
						"sku": "F-AKASHI-BG",
						"inventorySku": "F-AKASHI-BG",
						"inventoryId": "MV6528EC61CFF47E0001D97D5F",
						"variationName": "BLACK - GOLD",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833749525672726528",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:08:50Z",
				"externalUpdateDatetime": "2024-09-11T06:08:51Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:11:26Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E133D446E0FB000120C0C1",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NBNUJ0B3",
				"externalOrderSN": "240911NBNUJ0B3",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 21650,
				"totalQuantity": 2,
				"customerName": "R******a",
				"customerMobile": "******86",
				"recipientName": "R******a",
				"logisticsProviderName": "SPX Instant",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E133D446E0FB000120C0C4",
						"externalOrderItemId": "240911NBNUJ0B3-0",
						"externalVariationId": "5856624982",
						"externalStatus": null,
						"productName": "Pembersih Lensa Kacamata (Kama Lens Cleaner)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98o-lpei3gpk6jqy7d_tn",
						"quantity": 2,
						"actualPrice": 12500,
						"sku": "LNS-CLNR",
						"inventorySku": "LNS-CLNR",
						"inventoryId": "MV6528EC61CFF47E0001D97CF5",
						"variationName": null,
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Instant",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833749397851332608",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_LABEL",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_LABEL",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:08:19Z",
				"externalUpdateDatetime": "2024-09-11T06:08:29Z",
				"promiseShipBefore": "2024-09-11T16:59:59Z",
				"payAtDatetime": "2024-09-11T06:08:26Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:11:26Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E133A446E0FB000101F6F9",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NBMDSYYC",
				"externalOrderSN": "240911NBMDSYYC",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 95173,
				"totalQuantity": 1,
				"customerName": "M******a",
				"customerMobile": "******40",
				"recipientName": "M******a",
				"logisticsProviderName": "SPX Hemat",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E133A446E0FB000101F6FC",
						"externalOrderItemId": "240911NBMDSYYC-0",
						"externalVariationId": "79020556791",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Anya (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qul0-lgi8p51lki6314_tn",
						"quantity": 1,
						"actualPrice": 109900,
						"sku": "F-ANYA-CR",
						"inventorySku": "F-ANYA-CR",
						"inventoryId": "MV6528EC61CFF47E0001D97DC8",
						"variationName": "CRYSTAL",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833749197778833408",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:07:31Z",
				"externalUpdateDatetime": "2024-09-11T06:07:54Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T06:07:52Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:11:26Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E133774CEDFD000186A29B",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NBK3VSNU",
				"externalOrderSN": "240911NBK3VSNU",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 357658,
				"totalQuantity": 2,
				"customerName": "D******i",
				"customerMobile": "******26",
				"recipientName": "D******i",
				"logisticsProviderName": "SPX Hemat",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E133774CEDFD000186A29E",
						"externalOrderItemId": "240911NBK3VSNU-0",
						"externalVariationId": "15031619616",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus Photochromic (SPH/CYL) PC",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 189000,
						"sku": "LNS-PC-MIN-3.25",
						"inventorySku": "LNS-PC-MIN-3.25",
						"inventoryId": "MV6528EC61CFF47E0001D97D28",
						"variationName": "PHOTOCHROMIC,MIN -3.25 s/d -6.00",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833749009450385408",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E133774CEDFD000186A29F",
						"externalOrderItemId": "240911NBK3VSNU-1",
						"externalVariationId": "207757087647",
						"externalStatus": null,
						"productName": "heykama -  Hajime ( Frame Kacamata  Rectangle ) - Active Progress Collection",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98y-lzxe4bobwcf2b7_tn",
						"quantity": 1,
						"actualPrice": 239000,
						"sku": "F-HAJIME-BG",
						"inventorySku": "F-HAJIME-BG",
						"inventoryId": "MV66DDA93DC9E77C000178422C",
						"variationName": "Black Glossy",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833749009450385408",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:06:46Z",
				"externalUpdateDatetime": "2024-09-11T06:07:19Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T06:07:18Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:11:26Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E1336346E0FB000145BF40",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NBJFU0M7",
				"externalOrderSN": "240911NBJFU0M7",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 226372,
				"totalQuantity": 2,
				"customerName": "D******)",
				"customerMobile": "******33",
				"recipientName": "D******)",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "Kanan -01,00 kiri -01,25",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E1336346E0FB000145BF43",
						"externalOrderItemId": "240911NBJFU0M7-0",
						"externalVariationId": "15032932301",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus Blueray Antiradiasi (SPH/CYL) BR",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 166500,
						"sku": "LNS-BR-MIN-0.25",
						"inventorySku": "LNS-BR-MIN-0.25",
						"inventoryId": "MV6528EC61CFF47E0001D97CBB",
						"variationName": "BLUERAY,MIN -0.25 s/d -3.00",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833748925820141568",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E1336346E0FB000145BF44",
						"externalOrderItemId": "240911NBJFU0M7-1",
						"externalVariationId": "79020556790",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Anya (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qukw-lgi8p51lj3ln5e_tn",
						"quantity": 1,
						"actualPrice": 109900,
						"sku": "F-ANYA-M",
						"inventorySku": "F-ANYA-M",
						"inventoryId": "MV6528EC61CFF47E0001D97DC7",
						"variationName": "MIST",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833748925820141568",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:06:26Z",
				"externalUpdateDatetime": "2024-09-11T06:07:25Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T06:07:24Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:11:26Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E13321C9E77C00011E1713",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE61CC4CEDFD00016CF12F",
				"shopName": "Hey.Kama",
				"externalOrderId": "577206121417573588",
				"externalOrderSN": "577206121417573588",
				"orderStatus": "PAID",
				"externalOrderStatus": "AWAITING_SHIPMENT",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "TIKTOK_ID",
				"channelName": "TikTok",
				"paymentMethod": "COD",
				"orderType": "NORMAL",
				"fulfillBy": "FULFILLMENTBYSELLER",
				"sellerTotalAmount": 294900,
				"totalQuantity": 2,
				"customerName": "m*** p***la",
				"customerMobile": "+62 82160",
				"recipientName": "m*** p***la",
				"logisticsProviderName": "J&T Express",
				"logisticsTrackingNumber": "",
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "ukuran lensa nya saya kirim foto di chat ya",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E13321C9E77C00011E1716",
						"externalOrderItemId": "577206121417573588-1729905123362899088",
						"externalVariationId": "1729905123362899088",
						"externalStatus": "AWAITING_SHIPMENT",
						"productName": "HeyKama - Lensa Blueray Antiradiasi Bening Custom",
						"productImage": "https://p16-oec-va.ibyteimg.com/tos-maliva-i-o3syd03w52-us/a4713c1b75254c71bc9f02a2464ebe2e~tplv-o3syd03w52-origin-jpeg.jpeg?from=1413970683",
						"quantity": 1,
						"actualPrice": 200146,
						"sku": "LNS-BR-MIN-3.25",
						"inventorySku": "LNS-BR-MIN-3.25",
						"inventoryId": "MV6528EC61CFF47E0001D97CBC",
						"variationName": "MIN -3.25 s/d -6.00",
						"fulfillBy": "FULFILLMENT_BY_SELLER",
						"packageId": "1154837165065602260",
						"invoiceNumber": null,
						"logisticsProviderName": "J&T Express",
						"logisticsTrackingNumber": "",
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833748647821672448",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "7049609457587816194",
								"warehouseName": "ID Shipping Warehouse",
								"warehouseType": "CHANNEL",
								"warehouseCode": null
							},
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E13321C9E77C00011E1717",
						"externalOrderItemId": "577206121417573588-1729588347956136080",
						"externalVariationId": "1729588347956136080",
						"externalStatus": "AWAITING_SHIPMENT",
						"productName": "HeyKama - Frame Kacamata Miki - Kacamata Cat Eye",
						"productImage": "https://p16-oec-va.ibyteimg.com/tos-maliva-i-o3syd03w52-us/ffab5614de63495890d6a296f1984d24~tplv-o3syd03w52-origin-jpeg.jpeg?from=1413970683",
						"quantity": 1,
						"actualPrice": 91854,
						"sku": "F-MIKI-BRG",
						"inventorySku": "F-MIKI-BRG",
						"inventoryId": "MV6528EC61CFF47E0001D97D78",
						"variationName": "Black Rosegold",
						"fulfillBy": "FULFILLMENT_BY_SELLER",
						"packageId": "1154837165065602260",
						"invoiceNumber": null,
						"logisticsProviderName": "J&T Express",
						"logisticsTrackingNumber": "",
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833748647821672448",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "7049609457587816194",
								"warehouseName": "ID Shipping Warehouse",
								"warehouseType": "CHANNEL",
								"warehouseCode": null
							},
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:05:17Z",
				"externalUpdateDatetime": "2024-09-11T06:05:19Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:05:23Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E132BC46E0FB0001E2DF59",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NBDGHCJR",
				"externalOrderSN": "240911NBDGHCJR",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "PRE_SALE",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 647656,
				"totalQuantity": 2,
				"customerName": "N******H",
				"customerMobile": "******54",
				"recipientName": "N******H",
				"logisticsProviderName": "SPX Hemat",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "kanan 5.25,  kiri 3.50 & silinder kanan kiri 1.50",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E132BC46E0FB0001E2DF5C",
						"externalOrderItemId": "240911NBDGHCJR-0",
						"externalVariationId": "132101446750",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Xiomi (CatEye)",
						"productImage": "https://cf.shopee.co.id/file/sg-11134201-7rbmk-lmjt9ypahbh6ec_tn",
						"quantity": 1,
						"actualPrice": 95000,
						"sku": "F-XIOMI-M",
						"inventorySku": "F-XIOMI-M",
						"inventoryId": "MV6528EC61CFF47E0001D97DA4",
						"variationName": "MIST (ABU-ABU)",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833748226155724800",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E132BC46E0FB0001E2DF5D",
						"externalOrderItemId": "240911NBDGHCJR-1",
						"externalVariationId": "186889139023",
						"externalStatus": null,
						"productName": "heykama - Lensa Tipis / HI-Index ( SPH/CYL )",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qul8-ljcg3gmtustm1f_tn",
						"quantity": 1,
						"actualPrice": 650000,
						"sku": "HI-BR-1.67",
						"inventorySku": "HI-BR-1.67",
						"inventoryId": "MV6528EC61CFF47E0001D97E55",
						"variationName": "Blueray MAX CYL -200,Index 1.67",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833748226155724800",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:03:39Z",
				"externalUpdateDatetime": "2024-09-11T06:04:14Z",
				"promiseShipBefore": "2024-09-26T06:04:14Z",
				"payAtDatetime": "2024-09-11T06:04:13Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:05:28Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E1328946E0FB0001459E2F",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NBBY06H8",
				"externalOrderSN": "240911NBBY06H8",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "COD",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 82183,
				"totalQuantity": 1,
				"customerName": "A******)",
				"customerMobile": "******42",
				"recipientName": "A******)",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E1328946E0FB0001459E32",
						"externalOrderItemId": "240911NBBY06H8-0",
						"externalVariationId": "79020556789",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Anya (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qul0-lgi8p51lhp1713_tn",
						"quantity": 1,
						"actualPrice": 109900,
						"sku": "F-ANYA-BG",
						"inventorySku": "F-ANYA-BG",
						"inventoryId": "MV6528EC61CFF47E0001D97DC6",
						"variationName": "BLACK GOLD",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833748010916610048",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:02:48Z",
				"externalUpdateDatetime": "2024-09-11T06:02:50Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:05:28Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E13273C9E77C0001ED5BBC",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NBBAX02F",
				"externalOrderSN": "240911NBBAX02F",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "COD",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 64863,
				"totalQuantity": 1,
				"customerName": "A******n",
				"customerMobile": "******86",
				"recipientName": "A******n",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E13273C9E77C0001ED5BBF",
						"externalOrderItemId": "240911NBBAX02F-0",
						"externalVariationId": "86145948339",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Sora (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98q-lpbp9kt0cjt9ac_tn",
						"quantity": 1,
						"actualPrice": 89900,
						"sku": "F-SORA-SB",
						"inventorySku": "F-SORA-SB",
						"inventoryId": "MV6528EC61CFF47E0001D97D90",
						"variationName": "SOFT BROWN",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833747919132659712",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:02:26Z",
				"externalUpdateDatetime": "2024-09-11T06:02:28Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:05:28Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E132544CEDFD0001867863",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NBADBR17",
				"externalOrderSN": "240911NBADBR17",
				"orderStatus": "PENDING_PAYMENT",
				"externalOrderStatus": "UNPAID",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 77940,
				"totalQuantity": 1,
				"customerName": "D******i",
				"customerMobile": "******92",
				"recipientName": "D******i",
				"logisticsProviderName": "Hemat",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": false,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E132544CEDFD0001867866",
						"externalOrderItemId": "240911NBADBR17-0",
						"externalVariationId": "180503471697",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Akika (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qul9-lgi8l2wlfc8839_tn",
						"quantity": 1,
						"actualPrice": 105000,
						"sku": "F-AKIKA-M",
						"inventorySku": "F-AKIKA-M",
						"inventoryId": "MV6528EC61CFF47E0001D97D4A",
						"variationName": "MIST",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833747788639473664",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T06:01:55Z",
				"externalUpdateDatetime": "2024-09-11T06:01:55Z",
				"promiseShipBefore": null,
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T06:01:57Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E1311F46E0FB000120571A",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NB16NT9P",
				"externalOrderSN": "240911NB16NT9P",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "COD",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 69280,
				"totalQuantity": 1,
				"customerName": "Q******a",
				"customerMobile": "******93",
				"recipientName": "Q******a",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E1311F46E0FB000120571D",
						"externalOrderItemId": "240911NB16NT9P-0",
						"externalVariationId": "134255435104",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Ayumi (CatEye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qukx-lgi96p7io2zta5_tn",
						"quantity": 1,
						"actualPrice": 95000,
						"sku": "F-AYUMI-BG",
						"inventorySku": "F-AYUMI-BG",
						"inventoryId": "MV6528EC61CFF47E0001D97D3D",
						"variationName": "BLACK GOLD",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833746491190251520",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:56:46Z",
				"externalUpdateDatetime": "2024-09-11T05:56:48Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:59:40Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E1310C46E0FB0001019242",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE61CC4CEDFD00016CF12F",
				"shopName": "Hey.Kama",
				"externalOrderId": "577206094907016215",
				"externalOrderSN": "577206094907016215",
				"orderStatus": "PAID",
				"externalOrderStatus": "AWAITING_SHIPMENT",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "TIKTOK_ID",
				"channelName": "TikTok",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "FULFILLMENTBYSELLER",
				"sellerTotalAmount": 89900,
				"totalQuantity": 1,
				"customerName": "H***a",
				"customerMobile": "+62 82100",
				"recipientName": "H***a",
				"logisticsProviderName": "J&T Express",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E1310C46E0FB0001019245",
						"externalOrderItemId": "577206094907016215-1729461652164020368",
						"externalVariationId": "1729461652164020368",
						"externalStatus": "AWAITING_SHIPMENT",
						"productName": "HeyKama - Frame Kacamata Anya ( Kacamata Cat Eye )",
						"productImage": "https://p16-oec-va.ibyteimg.com/tos-maliva-i-o3syd03w52-us/6af207655ba84c028840ea9ece4a86b9~tplv-o3syd03w52-origin-jpeg.jpeg?from=1413970683",
						"quantity": 1,
						"actualPrice": 89900,
						"sku": "F-ANYA-M",
						"inventorySku": "F-ANYA-M",
						"inventoryId": "MV6528EC61CFF47E0001D97DC7",
						"variationName": "MIST",
						"fulfillBy": "FULFILLMENT_BY_SELLER",
						"packageId": "1154837017076073495",
						"invoiceNumber": null,
						"logisticsProviderName": "J&T Express",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833746409820753920",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "7049609457587816194",
								"warehouseName": "ID Shipping Warehouse",
								"warehouseType": "CHANNEL",
								"warehouseCode": null
							},
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:56:25Z",
				"externalUpdateDatetime": "2024-09-11T05:56:44Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:56:44Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:56:48Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E1305246E0FB0001E281B5",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE61CC4CEDFD00016CF12F",
				"shopName": "Hey.Kama",
				"externalOrderId": "577206086468207865",
				"externalOrderSN": "577206086468207865",
				"orderStatus": "PENDING_PAYMENT",
				"externalOrderStatus": "UNPAID",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "TIKTOK_ID",
				"channelName": "TikTok",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "FULFILLMENTBYSELLER",
				"sellerTotalAmount": 90900,
				"totalQuantity": 1,
				"customerName": null,
				"customerMobile": null,
				"recipientName": null,
				"logisticsProviderName": null,
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E1305246E0FB0001E281B8",
						"externalOrderItemId": "577206086468207865-1729577023867226256",
						"externalVariationId": "1729577023867226256",
						"externalStatus": "UNPAID",
						"productName": "HeyKama - Frame Kacamata Anya ( Kacamata Cat Eye )",
						"productImage": "https://p16-oec-va.ibyteimg.com/tos-maliva-i-o3syd03w52-us/6111b57c630e4844a14750bfebcf2028~tplv-o3syd03w52-origin-jpeg.jpeg?from=1413970683",
						"quantity": 1,
						"actualPrice": 89900,
						"sku": "F-ANYA-BL",
						"inventorySku": "F-ANYA-BL",
						"inventoryId": "MV6528EC61CFF47E0001D97DC9",
						"variationName": "FULL BLACK",
						"fulfillBy": "FULFILLMENT_BY_SELLER",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": null,
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833745630472925184",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "7049609457587816194",
								"warehouseName": "ID Shipping Warehouse",
								"warehouseType": "CHANNEL",
								"warehouseCode": null
							},
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:53:19Z",
				"externalUpdateDatetime": "2024-09-11T05:53:20Z",
				"promiseShipBefore": null,
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:53:22Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12FF34CEDFD00018618B3",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NAR7N8CN",
				"externalOrderSN": "240911NAR7N8CN",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 254604,
				"totalQuantity": 2,
				"customerName": "R******a",
				"customerMobile": "******45",
				"recipientName": "R******a",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12FF34CEDFD00018618B9",
						"externalOrderItemId": "240911NAR7N8CN-0",
						"externalVariationId": "68426386851",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus Blueray Antiradiasi (SPH/CYL) BR",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 180000,
						"sku": "LNS-BR-MAX-3.00",
						"inventorySku": "LNS-BR-MAX-3.00",
						"inventoryId": "MV6528EC61CFF47E0001D97CBE",
						"variationName": "BLUERAY,MAX -3.00 CYL -2.00",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833745235537289216",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E12FF34CEDFD00018618BA",
						"externalOrderItemId": "240911NAR7N8CN-1",
						"externalVariationId": "217628329120",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Hiro ( Rectangle )",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98r-lxal65fjoroqa5_tn",
						"quantity": 1,
						"actualPrice": 129000,
						"sku": "F-HIRO-CR",
						"inventorySku": "F-HIRO-CR",
						"inventoryId": "MV6686157BC9E77C00013E24C9",
						"variationName": "Crystal",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833745235537289216",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:51:46Z",
				"externalUpdateDatetime": "2024-09-11T05:51:59Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:51:58Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:54:03Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12FEC46E0FB00010164CF",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE61CC4CEDFD00016CF12F",
				"shopName": "Hey.Kama",
				"externalOrderId": "577206081546782629",
				"externalOrderSN": "577206081546782629",
				"orderStatus": "PAID",
				"externalOrderStatus": "AWAITING_SHIPMENT",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "TIKTOK_ID",
				"channelName": "TikTok",
				"paymentMethod": "COD",
				"orderType": "NORMAL",
				"fulfillBy": "FULFILLMENTBYSELLER",
				"sellerTotalAmount": 92400,
				"totalQuantity": 1,
				"customerName": "Y*** R***at",
				"customerMobile": "+62 85216",
				"recipientName": "Y*** R***at",
				"logisticsProviderName": "J&T Express",
				"logisticsTrackingNumber": "",
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "mau pesen frame dan ganti lensa yg antiradiasi minus 3 tp gk ada pilihan ya",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12FEC46E0FB00010164D2",
						"externalOrderItemId": "577206081546782629-1729443383825107088",
						"externalVariationId": "1729443383825107088",
						"externalStatus": "AWAITING_SHIPMENT",
						"productName": "HeyKama - Frame Kacamata Miki - Kacamata Cat Eye",
						"productImage": "https://p16-oec-va.ibyteimg.com/tos-maliva-i-o3syd03w52-us/8ef4225bdae14a77a559f949862df9b5~tplv-o3syd03w52-origin-jpeg.jpeg?from=1413970683",
						"quantity": 1,
						"actualPrice": 89900,
						"sku": "F-MIKI-BL",
						"inventorySku": "F-MIKI-BL",
						"inventoryId": "MV6528EC61CFF47E0001D97D71",
						"variationName": "Full Black",
						"fulfillBy": "FULFILLMENT_BY_SELLER",
						"packageId": "1154836970000779173",
						"invoiceNumber": null,
						"logisticsProviderName": "J&T Express",
						"logisticsTrackingNumber": "",
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833745203857707008",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "7049609457587816194",
								"warehouseName": "ID Shipping Warehouse",
								"warehouseType": "CHANNEL",
								"warehouseCode": null
							},
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:51:36Z",
				"externalUpdateDatetime": "2024-09-11T05:51:38Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:51:41Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12FB846E0FB0001452E29",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NAPGA56R",
				"externalOrderSN": "240911NAPGA56R",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 151896,
				"totalQuantity": 2,
				"customerName": "a****d",
				"customerMobile": "******87",
				"recipientName": "a****d",
				"logisticsProviderName": "SPX Hemat",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12FB846E0FB0001452E2C",
						"externalOrderItemId": "240911NAPGA56R-0",
						"externalVariationId": "217276198838",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Arazu",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98t-lqljfibwntpa4e_tn",
						"quantity": 1,
						"actualPrice": 89900,
						"sku": "F-ARAZU-CR",
						"inventorySku": "F-ARAZU-CR",
						"inventoryId": "MV65A76D04C9E77C000124373A",
						"variationName": "CRYSTAL",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833744986068455424",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E12FB846E0FB0001452E2D",
						"externalOrderItemId": "240911NAPGA56R-1",
						"externalVariationId": "15001780827",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus{{$t('Clear')}}(SPH/CYL) CR",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 85500,
						"sku": "LNS-CR-MIN-0.25",
						"inventorySku": "LNS-CR-MIN-0.25",
						"inventoryId": "MV6528EC61CFF47E0001D97D01",
						"variationName": "CLEAR,MIN -0.25 s/d -3.00",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833744986068455424",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:50:47Z",
				"externalUpdateDatetime": "2024-09-11T05:51:55Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:51:52Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:54:03Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12ED94CEDFD000185EA9D",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NAFTQFWP",
				"externalOrderSN": "240911NAFTQFWP",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 82270,
				"totalQuantity": 1,
				"customerName": "S******a",
				"customerMobile": "******79",
				"recipientName": "S******a",
				"logisticsProviderName": "SPX Hemat",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12ED94CEDFD000185EAA0",
						"externalOrderItemId": "240911NAFTQFWP-0",
						"externalVariationId": "58093299704",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Xiomi (CatEye)",
						"productImage": "https://cf.shopee.co.id/file/sg-11134201-7rblp-lnc8zsa2rvu91f_tn",
						"quantity": 1,
						"actualPrice": 95000,
						"sku": "F-XIOMI-DB",
						"inventorySku": "F-XIOMI-DB",
						"inventoryId": "MV6528EC61CFF47E0001D97DA7",
						"variationName": "DARK BROWN (TUA)",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833744049845293056",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:47:04Z",
				"externalUpdateDatetime": "2024-09-11T05:47:38Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:47:34Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:54:03Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12EC14CEDFD000185E730",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NAF4T0R0",
				"externalOrderSN": "240911NAF4T0R0",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 86513,
				"totalQuantity": 1,
				"customerName": "R******n",
				"customerMobile": "******64",
				"recipientName": "R******n",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12EC14CEDFD000185E733",
						"externalOrderItemId": "240911NAF4T0R0-0",
						"externalVariationId": "132101446749",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Xiomi (CatEye)",
						"productImage": "https://cf.shopee.co.id/file/sg-11134201-7rbkm-lmjt9xmg25wa9f_tn",
						"quantity": 1,
						"actualPrice": 69900,
						"sku": "F-XIOMI-BG",
						"inventorySku": "F-XIOMI-BG",
						"inventoryId": "MV6528EC61CFF47E0001D97DA2",
						"variationName": "BLACK GLOSSY (KILAP)",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833743950037630976",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:46:40Z",
				"externalUpdateDatetime": "2024-09-11T05:48:18Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:48:16Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:54:03Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12EA746E0FB0001E2371B",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NAEBXATE",
				"externalOrderSN": "240911NAEBXATE",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 211910,
				"totalQuantity": 2,
				"customerName": "D******i",
				"customerMobile": "******58",
				"recipientName": "D******i",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12EA746E0FB0001E2371E",
						"externalOrderItemId": "240911NAEBXATE-0",
						"externalVariationId": "15031619614",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus Photochromic (SPH/CYL) PC",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 120900,
						"sku": "LNS-PC-PLANO",
						"inventorySku": "LNS-PC-MN",
						"inventoryId": "MV6528EC61CFF47E0001D97D26",
						"variationName": "PHOTOCHROMIC,MATA NORMAL",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833743842667646976",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E12EA746E0FB0001E2371F",
						"externalOrderItemId": "240911NAEBXATE-1",
						"externalVariationId": "79020556789",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Anya (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qul0-lgi8p51lhp1713_tn",
						"quantity": 1,
						"actualPrice": 64900,
						"sku": "F-ANYA-BG",
						"inventorySku": "F-ANYA-BG",
						"inventoryId": "MV6528EC61CFF47E0001D97DC6",
						"variationName": "BLACK GOLD",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833743842667646976",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:46:15Z",
				"externalUpdateDatetime": "2024-09-11T05:57:13Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:57:12Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:59:40Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12E704CEDFD000185DBC2",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NACQFMCK",
				"externalOrderSN": "240911NACQFMCK",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 175798,
				"totalQuantity": 2,
				"customerName": "B******h",
				"customerMobile": "******42",
				"recipientName": "B******h",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "Lensa R (-3.50) , L (-3.25)",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12E704CEDFD000185DBC5",
						"externalOrderItemId": "240911NACQFMCK-0",
						"externalVariationId": "15001780828",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus{{$t('Clear')}}(SPH/CYL) CR",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 108000,
						"sku": "LNS-CR-MIN-3.25",
						"inventorySku": "LNS-CR-MIN-3.25",
						"inventoryId": "MV6528EC61CFF47E0001D97D02",
						"variationName": "CLEAR,MIN -3.25 s/d -6.00",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833743611863486464",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E12E704CEDFD000185DBC6",
						"externalOrderItemId": "240911NACQFMCK-1",
						"externalVariationId": "213944182883",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Sanmu (CatEye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98s-lp0o8m9mwccz69_tn",
						"quantity": 1,
						"actualPrice": 95000,
						"sku": "F-SANMU-BRGRAD",
						"inventorySku": "F-SANMU-BRGRAD",
						"inventoryId": "MV6528EC61CFF47E0001D97D10",
						"variationName": "BROWN GRADASI",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833743611863486464",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:45:19Z",
				"externalUpdateDatetime": "2024-09-11T05:48:04Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:48:02Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:54:03Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12DC34CEDFD000185B678",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NA7JGBTE",
				"externalOrderSN": "240911NA7JGBTE",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 77853,
				"totalQuantity": 1,
				"customerName": "A***l",
				"customerMobile": "******76",
				"recipientName": "A***l",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12DC34CEDFD000185B67E",
						"externalOrderItemId": "240911NA7JGBTE-0",
						"externalVariationId": "86145948339",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Sora (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98q-lpbp9kt0cjt9ac_tn",
						"quantity": 1,
						"actualPrice": 89900,
						"sku": "F-SORA-SB",
						"inventorySku": "F-SORA-SB",
						"inventoryId": "MV6528EC61CFF47E0001D97D90",
						"variationName": "SOFT BROWN",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833742884994772992",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:42:26Z",
				"externalUpdateDatetime": "2024-09-11T05:42:44Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:42:42Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:47:24Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12DA3C9E77C00011D2D99",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NA6M06Y8",
				"externalOrderSN": "240911NA6M06Y8",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 225160,
				"totalQuantity": 2,
				"customerName": "R******n",
				"customerMobile": "******93",
				"recipientName": "R******n",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "silinder mata kiri 1,5 kanan 1",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12DA3C9E77C00011D2D9C",
						"externalOrderItemId": "240911NA6M06Y8-0",
						"externalVariationId": "68426386851",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus Blueray Antiradiasi (SPH/CYL) BR",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 180000,
						"sku": "LNS-BR-MAX-3.00",
						"inventorySku": "LNS-BR-MAX-3.00",
						"inventoryId": "MV6528EC61CFF47E0001D97CBE",
						"variationName": "BLUERAY,MAX -3.00 CYL -2.00",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833742749820751872",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E12DA3C9E77C00011D2D9D",
						"externalOrderItemId": "240911NA6M06Y8-1",
						"externalVariationId": "163509587159",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Xiomi (CatEye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98v-luyjlk4gxjjb07_tn",
						"quantity": 1,
						"actualPrice": 95000,
						"sku": "F-XIOMI-SB",
						"inventorySku": "F-XIOMI-SB",
						"inventoryId": "MV6528EC61CFF47E0001D97DA6",
						"variationName": "SOFT BROWN (MUDA)",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833742749820751872",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:41:54Z",
				"externalUpdateDatetime": "2024-09-11T05:42:10Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:42:09Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:47:24Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12D2D46E0FB00011F94E6",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NA33FNX0",
				"externalOrderSN": "240911NA33FNX0",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 127518,
				"totalQuantity": 1,
				"customerName": "H******b",
				"customerMobile": "******11",
				"recipientName": "H******b",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "Frame nya jine+blue ray mata normal",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12D2D46E0FB00011F94E9",
						"externalOrderItemId": "240911NA33FNX0-0",
						"externalVariationId": "15032932299",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus Blueray Antiradiasi (SPH/CYL) BR",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 118900,
						"sku": "LNS-BR-PLANO",
						"inventorySku": "LNS-BR-MN",
						"inventoryId": "MV6528EC61CFF47E0001D97CBA",
						"variationName": "BLUERAY,MATA NORMAL",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833742253638803456",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:39:56Z",
				"externalUpdateDatetime": "2024-09-11T05:40:04Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:40:02Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:42:04Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12CFE46E0FB0001E1D03D",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NA1PNMP2",
				"externalOrderSN": "240911NA1PNMP2",
				"orderStatus": "PENDING_PAYMENT",
				"externalOrderStatus": "UNPAID",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 77940,
				"totalQuantity": 1,
				"customerName": "I******e",
				"customerMobile": "******76",
				"recipientName": "I******e",
				"logisticsProviderName": "Hemat",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": false,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12CFE46E0FB0001E1D040",
						"externalOrderItemId": "240911NA1PNMP2-0",
						"externalVariationId": "162885062317",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Akika (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7quky-lgi8l2wlb4iw44_tn",
						"quantity": 1,
						"actualPrice": 105000,
						"sku": "F-AKIKA-BG",
						"inventorySku": "F-AKIKA-BG",
						"inventoryId": "MV6528EC61CFF47E0001D97D47",
						"variationName": "BLACK GLOSSY",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833742056514899968",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:39:09Z",
				"externalUpdateDatetime": "2024-09-11T05:39:09Z",
				"promiseShipBefore": null,
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:39:10Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12CFDC9E77C0001EC6A52",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NA1NNX8Y",
				"externalOrderSN": "240911NA1NNX8Y",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "COD",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 10825,
				"totalQuantity": 1,
				"customerName": "V******)",
				"customerMobile": "******60",
				"recipientName": "V******)",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12CFDC9E77C0001EC6A55",
						"externalOrderItemId": "240911NA1NNX8Y-0",
						"externalVariationId": "5856624982",
						"externalStatus": null,
						"productName": "Pembersih Lensa Kacamata (Kama Lens Cleaner)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98o-lpei3gpk6jqy7d_tn",
						"quantity": 1,
						"actualPrice": 12500,
						"sku": "LNS-CLNR",
						"inventorySku": "LNS-CLNR",
						"inventoryId": "MV6528EC61CFF47E0001D97CF5",
						"variationName": null,
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833742054610690048",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:39:08Z",
				"externalUpdateDatetime": "2024-09-11T05:39:10Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:42:04Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12CE9C9E77C0001EC6764",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NA12KG5U",
				"externalOrderSN": "240911NA12KG5U",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "COD",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 82270,
				"totalQuantity": 1,
				"customerName": "I******i",
				"customerMobile": "******47",
				"recipientName": "I******i",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12CE9C9E77C0001EC6767",
						"externalOrderItemId": "240911NA12KG5U-0",
						"externalVariationId": "163509587159",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Xiomi (CatEye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98v-luyjlk4gxjjb07_tn",
						"quantity": 1,
						"actualPrice": 95000,
						"sku": "F-XIOMI-SB",
						"inventorySku": "F-XIOMI-SB",
						"inventoryId": "MV6528EC61CFF47E0001D97DA6",
						"variationName": "SOFT BROWN (MUDA)",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833741970179342336",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:38:48Z",
				"externalUpdateDatetime": "2024-09-11T05:38:51Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:42:04Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12CDF46E0FB00011F85C2",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911NA0R5F7F",
				"externalOrderSN": "240911NA0R5F7F",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 216413,
				"totalQuantity": 1,
				"customerName": "H******b",
				"customerMobile": "******11",
				"recipientName": "H******b",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "Lensa nya yg blueray mata normal ka",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12CDF46E0FB00011F85C5",
						"externalOrderItemId": "240911NA0R5F7F-0",
						"externalVariationId": "216735640914",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Jine - Sunyata Collection",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qukw-lgm9xbekomi3c9_tn",
						"quantity": 1,
						"actualPrice": 249900,
						"sku": "F-JINE-BG",
						"inventorySku": "F-JINE-BG",
						"inventoryId": "MV6528EC61CFF47E0001D97DF6",
						"variationName": "BLACK GLOSSY",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833741926684393472",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:38:38Z",
				"externalUpdateDatetime": "2024-09-11T05:38:48Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:38:47Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:42:04Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12C854CEDFD0001857B34",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911N9X2AVWY",
				"externalOrderSN": "240911N9X2AVWY",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 290896,
				"totalQuantity": 2,
				"customerName": "M******i",
				"customerMobile": "******84",
				"recipientName": "M******i",
				"logisticsProviderName": "J&T Express",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "minus kanan dan kiri -1.00, minta di proses yang rapih ya kak, dan packing safely.",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12C854CEDFD0001857B37",
						"externalOrderItemId": "240911N9X2AVWY-0",
						"externalVariationId": "15001780827",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus{{$t('Clear')}}(SPH/CYL) CR",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 85500,
						"sku": "LNS-CR-MIN-0.25",
						"inventorySku": "LNS-CR-MIN-0.25",
						"inventoryId": "MV6528EC61CFF47E0001D97D01",
						"variationName": "CLEAR,MIN -0.25 s/d -3.00",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "J&T Express",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833741550128185344",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E12C854CEDFD0001857B38",
						"externalOrderItemId": "240911N9X2AVWY-1",
						"externalVariationId": "225813232883",
						"externalStatus": null,
						"productName": "heykama - Shades Of Hanami ( Frame Kacamata Round )",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98u-lo7s59whqosj9d_tn",
						"quantity": 1,
						"actualPrice": 299900,
						"sku": "F-HANAMI-L",
						"inventorySku": "F-HANAMI-L",
						"inventoryId": "MV65584ED5CFF47E00012C44CE",
						"variationName": "Lime",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "J&T Express",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833741550128185344",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:37:08Z",
				"externalUpdateDatetime": "2024-09-11T05:42:32Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:42:31Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:47:24Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12C5946E0FB00011F707E",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911N9VSAJ7J",
				"externalOrderSN": "240911N9VSAJ7J",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 21650,
				"totalQuantity": 2,
				"customerName": "R******)",
				"customerMobile": "******21",
				"recipientName": "R******)",
				"logisticsProviderName": "SPX Hemat",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12C5946E0FB00011F7081",
						"externalOrderItemId": "240911N9VSAJ7J-0",
						"externalVariationId": "5856624982",
						"externalStatus": null,
						"productName": "Pembersih Lensa Kacamata (Kama Lens Cleaner)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98o-lpei3gpk6jqy7d_tn",
						"quantity": 2,
						"actualPrice": 12500,
						"sku": "LNS-CLNR",
						"inventorySku": "LNS-CLNR",
						"inventoryId": "MV6528EC61CFF47E0001D97CF5",
						"variationName": null,
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833741367717904384",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:36:25Z",
				"externalUpdateDatetime": "2024-09-11T05:37:32Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:37:31Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:42:04Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12C1B46E0FB000100B7FE",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911N9TW640D",
				"externalOrderSN": "240911N9TW640D",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 303456,
				"totalQuantity": 2,
				"customerName": "F******m",
				"customerMobile": "******04",
				"recipientName": "F******m",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12C1B46E0FB000100B801",
						"externalOrderItemId": "240911N9TW640D-0",
						"externalVariationId": "16915958923",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus Bluechromic (SPH/CYL) BC",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 270000,
						"sku": "LNS-BC-PLANO",
						"inventorySku": "LNS-BC-MN",
						"inventoryId": "MV6528EC61CFF47E0001D97CDC",
						"variationName": "BLUECHROMIC,MATA NORMAL",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833741105615851520",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E12C1B46E0FB000100B802",
						"externalOrderItemId": "240911N9TW640D-1",
						"externalVariationId": "31696252611",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Inabe",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qul0-lgiaa8m7g5uw38_tn",
						"quantity": 1,
						"actualPrice": 95000,
						"sku": "F-INABE-SB",
						"inventorySku": "F-INABE-SB",
						"inventoryId": "MV6528EC61CFF47E0001D97CF9",
						"variationName": "SILVER BLACK",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833741105615851520",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:35:22Z",
				"externalUpdateDatetime": "2024-09-11T05:35:30Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:35:29Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:42:04Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12C1246E0FB0001E1AC6D",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911N9TMP48N",
				"externalOrderSN": "240911N9TMP48N",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "COD",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 222129,
				"totalQuantity": 2,
				"customerName": "P******)",
				"customerMobile": "******06",
				"recipientName": "P******)",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "kiri min 1 , kanan min 1",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12C1246E0FB0001E1AC70",
						"externalOrderItemId": "240911N9TMP48N-0",
						"externalVariationId": "15032932301",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus Blueray Antiradiasi (SPH/CYL) BR",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 166500,
						"sku": "LNS-BR-MIN-0.25",
						"inventorySku": "LNS-BR-MIN-0.25",
						"inventoryId": "MV6528EC61CFF47E0001D97CBB",
						"variationName": "BLUERAY,MIN -0.25 s/d -3.00",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833741067858702336",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E12C1246E0FB0001E1AC71",
						"externalOrderItemId": "240911N9TMP48N-1",
						"externalVariationId": "240702505922",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Okimi ( Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r992-lzhi6pcytbqlc4_tn",
						"quantity": 1,
						"actualPrice": 105000,
						"sku": "F-OKIMI-CR",
						"inventorySku": "F-OKIMI-CR",
						"inventoryId": "MV6628842BC9E77C000160EF0C",
						"variationName": "Crystal",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833741067858702336",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:35:13Z",
				"externalUpdateDatetime": "2024-09-11T05:35:15Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:42:04Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12C07C9E77C00011CE4D4",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE61CC4CEDFD00016CF12F",
				"shopName": "Hey.Kama",
				"externalOrderId": "577206038453192485",
				"externalOrderSN": "577206038453192485",
				"orderStatus": "PAID",
				"externalOrderStatus": "AWAITING_SHIPMENT",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "TIKTOK_ID",
				"channelName": "TikTok",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "FULFILLMENTBYSELLER",
				"sellerTotalAmount": 96000,
				"totalQuantity": 1,
				"customerName": "F***y",
				"customerMobile": "+62 87755",
				"recipientName": "F***y",
				"logisticsProviderName": "J&T Express",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12C07C9E77C00011CE4D7",
						"externalOrderItemId": "577206038453192485-1729443385545492624",
						"externalVariationId": "1729443385545492624",
						"externalStatus": "AWAITING_SHIPMENT",
						"productName": "HeyKama - Frame Kacamata Xiomi - Kacamata Cat Eye",
						"productImage": "https://p16-oec-va.ibyteimg.com/tos-maliva-i-o3syd03w52-us/181a513a96ef46b580897494b42a84d2~tplv-o3syd03w52-origin-jpeg.jpeg?from=1413970683",
						"quantity": 1,
						"actualPrice": 95000,
						"sku": "F-XIOMI-CR",
						"inventorySku": "F-XIOMI-CR",
						"inventoryId": "MV6528EC61CFF47E0001D97DA5",
						"variationName": "Crystal",
						"fulfillBy": "FULFILLMENT_BY_SELLER",
						"packageId": "1154836704740804389",
						"invoiceNumber": null,
						"logisticsProviderName": "J&T Express",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833741021918490624",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "7049609457587816194",
								"warehouseName": "ID Shipping Warehouse",
								"warehouseType": "CHANNEL",
								"warehouseCode": null
							},
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:35:00Z",
				"externalUpdateDatetime": "2024-09-11T05:35:51Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:35:51Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:35:55Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12BB546E0FB000144725C",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911N9QVUTTC",
				"externalOrderSN": "240911N9QVUTTC",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 303456,
				"totalQuantity": 2,
				"customerName": "N******i",
				"customerMobile": "******41",
				"recipientName": "N******i",
				"logisticsProviderName": "SPX Hemat",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "KANAN -1 KIRI -0,5, bluecromic. jangan sampai salah. terima kasih.",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12BB546E0FB0001447260",
						"externalOrderItemId": "240911N9QVUTTC-0",
						"externalVariationId": "16915958924",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus Bluechromic (SPH/CYL) BC",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 270000,
						"sku": "LNS-BC-MIN-0.25",
						"inventorySku": "LNS-BC-MIN-0.25",
						"inventoryId": "MV6528EC61CFF47E0001D97CDD",
						"variationName": "BLUECHROMIC,MIN -0.25 s/d -3.00",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833740678891552768",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E12BB546E0FB0001447261",
						"externalOrderItemId": "240911N9QVUTTC-1",
						"externalVariationId": "163509587159",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Xiomi (CatEye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98v-luyjlk4gxjjb07_tn",
						"quantity": 1,
						"actualPrice": 95000,
						"sku": "F-XIOMI-SB",
						"inventorySku": "F-XIOMI-SB",
						"inventoryId": "MV6528EC61CFF47E0001D97DA6",
						"variationName": "SOFT BROWN (MUDA)",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833740678891552768",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:33:40Z",
				"externalUpdateDatetime": "2024-09-11T05:33:49Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:33:48Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:35:45Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12AD746E0FB0001E17EF4",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911N9H956BA",
				"externalOrderSN": "240911N9H956BA",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 138906,
				"totalQuantity": 2,
				"customerName": "A******a",
				"customerMobile": "******89",
				"recipientName": "A******a",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "mohon segera di kirim🙏🏻",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12AD746E0FB0001E17EF7",
						"externalOrderItemId": "240911N9H956BA-0",
						"externalVariationId": "15001780825",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus{{$t('Clear')}}(SPH/CYL) CR",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 85500,
						"sku": "LNS-CR-PLANO",
						"inventorySku": "LNS-CR-PLANO",
						"inventoryId": "MV6528EC61CFF47E0001D97CFE",
						"variationName": "CLEAR,MATA NORMAL",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833739746678108160",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E12AD746E0FB0001E17EF8",
						"externalOrderItemId": "240911N9H956BA-1",
						"externalVariationId": "170462447674",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Sora (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qukx-lgiunfrvj01p47_tn",
						"quantity": 1,
						"actualPrice": 89900,
						"sku": "F-SORA-M",
						"inventorySku": "F-SORA-M",
						"inventoryId": "MV6528EC61CFF47E0001D97D91",
						"variationName": "MIST",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833739746678108160",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:29:58Z",
				"externalUpdateDatetime": "2024-09-11T05:30:09Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:30:08Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:35:45Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12A5F46E0FB0001443FF1",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911N9DPQ6YU",
				"externalOrderSN": "240911N9DPQ6YU",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "COD",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 90930,
				"totalQuantity": 1,
				"customerName": "K******i",
				"customerMobile": "******19",
				"recipientName": "K******i",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": "Kurniasi",
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12A5F46E0FB0001443FF4",
						"externalOrderItemId": "240911N9DPQ6YU-0",
						"externalVariationId": "105642112419",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Miura ( Rectangle )",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qul2-lgiryvheqip5f3_tn",
						"quantity": 1,
						"actualPrice": 105000,
						"sku": "F-MIURA-BG",
						"inventorySku": "F-MIURA-BG",
						"inventoryId": "MV6528EC61CFF47E0001D97D94",
						"variationName": "BLACK GLOSSY",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833739243147722752",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:27:58Z",
				"externalUpdateDatetime": "2024-09-11T05:28:00Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:30:03Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12A3CC9E77C00011C9F89",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911N9CMC6NY",
				"externalOrderSN": "240911N9CMC6NY",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 143323,
				"totalQuantity": 2,
				"customerName": "F******n",
				"customerMobile": "******64",
				"recipientName": "F******n",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "Kanan kiri minus (-3.00)",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12A3CC9E77C00011C9F8C",
						"externalOrderItemId": "240911N9CMC6NY-0",
						"externalVariationId": "15001780827",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus{{$t('Clear')}}(SPH/CYL) CR",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 85500,
						"sku": "LNS-CR-MIN-0.25",
						"inventorySku": "LNS-CR-MIN-0.25",
						"inventoryId": "MV6528EC61CFF47E0001D97D01",
						"variationName": "CLEAR,MIN -0.25 s/d -3.00",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833739096770711552",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E12A3CC9E77C00011C9F8D",
						"externalOrderItemId": "240911N9CMC6NY-1",
						"externalVariationId": "180503471697",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Akika (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qul9-lgi8l2wlfc8839_tn",
						"quantity": 1,
						"actualPrice": 105000,
						"sku": "F-AKIKA-M",
						"inventorySku": "F-AKIKA-M",
						"inventoryId": "MV6528EC61CFF47E0001D97D4A",
						"variationName": "MIST",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833739096770711552",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:27:23Z",
				"externalUpdateDatetime": "2024-09-11T05:27:32Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:27:30Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:30:03Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12A2D4CEDFD0001851F8F",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911N9C70W8D",
				"externalOrderSN": "240911N9C70W8D",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 250707,
				"totalQuantity": 2,
				"customerName": "K******i",
				"customerMobile": "******20",
				"recipientName": "K******i",
				"logisticsProviderName": "SPX Hemat",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12A2D4CEDFD0001851F92",
						"externalOrderItemId": "240911N9C70W8D-0",
						"externalVariationId": "142786819640",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus Photochromic (SPH/CYL) PC",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 184500,
						"sku": "LNS-PC-MAX-3.00",
						"inventorySku": "LNS-PC-MAX-3.00",
						"inventoryId": "MV6528EC61CFF47E0001D97D2A",
						"variationName": "PHOTOCHROMIC,MAX -3.00 CYL -2.00",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833739034258817024",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E12A2D4CEDFD0001851F93",
						"externalOrderItemId": "240911N9C70W8D-1",
						"externalVariationId": "240702505924",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Okimi ( Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98w-lwzlbsu9c4ga63_tn",
						"quantity": 1,
						"actualPrice": 105000,
						"sku": "F-OKIMI-M",
						"inventorySku": "F-OKIMI-M",
						"inventoryId": "MV6628842BC9E77C000160EF0E",
						"variationName": "Mist",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Hemat",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833739034258817024",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:27:08Z",
				"externalUpdateDatetime": "2024-09-11T05:27:19Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:27:18Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:30:03Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E1295446E0FB0001E1429A",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911N95Q4A7Y",
				"externalOrderSN": "240911N95Q4A7Y",
				"orderStatus": "CANCELLED",
				"externalOrderStatus": "CANCELLED",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 0,
				"totalQuantity": 2,
				"customerName": "F******n",
				"customerMobile": "******64",
				"recipientName": "F******n",
				"logisticsProviderName": "Reguler (Cashless)",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "Kana n dan kiri minus (-3.00)",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": false,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"FINISHED",
					"OWN_WAREHOUSE",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E1295446E0FB0001E1429D",
						"externalOrderItemId": "240911N95Q4A7Y-0",
						"externalVariationId": "15001780827",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus{{$t('Clear')}}(SPH/CYL) CR",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 85500,
						"sku": "LNS-CR-MIN-0.25",
						"inventorySku": "LNS-CR-MIN-0.25",
						"inventoryId": "MV6528EC61CFF47E0001D97D01",
						"variationName": "CLEAR,MIN -0.25 s/d -3.00",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "Reguler (Cashless)",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833738123671212032",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "INVALID",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E1295446E0FB0001E1429E",
						"externalOrderItemId": "240911N95Q4A7Y-1",
						"externalVariationId": "180503471697",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Akika (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qul9-lgi8l2wlfc8839_tn",
						"quantity": 1,
						"actualPrice": 105000,
						"sku": "F-AKIKA-M",
						"inventorySku": "F-AKIKA-M",
						"inventoryId": "MV6528EC61CFF47E0001D97D4A",
						"variationName": "MIST",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "Reguler (Cashless)",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833738123671212032",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "INVALID",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"VIEW_OPERATION_RECORD",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:23:31Z",
				"externalUpdateDatetime": "2024-09-11T05:24:04Z",
				"promiseShipBefore": null,
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": "2024-09-11T05:24:04Z",
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:24:05Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12863C9E77C00011C5693",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911N8XHAD8Q",
				"externalOrderSN": "240911N8XHAD8Q",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 228797,
				"totalQuantity": 2,
				"customerName": "f******t",
				"customerMobile": "******33",
				"recipientName": "f******t",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12863C9E77C00011C5696",
						"externalOrderItemId": "240911N8XHAD8Q-0",
						"externalVariationId": "15031619615",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus Photochromic (SPH/CYL) PC",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 169200,
						"sku": "LNS-PC-MIN-0.25",
						"inventorySku": "LNS-PC-MIN-0.25",
						"inventoryId": "MV6528EC61CFF47E0001D97D27",
						"variationName": "PHOTOCHROMIC,MIN -0.25 s/d -3.00",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833737113288536064",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E12863C9E77C00011C5697",
						"externalOrderItemId": "240911N8XHAD8Q-1",
						"externalVariationId": "163509587159",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Xiomi (CatEye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98v-luyjlk4gxjjb07_tn",
						"quantity": 1,
						"actualPrice": 95000,
						"sku": "F-XIOMI-SB",
						"inventorySku": "F-XIOMI-SB",
						"inventoryId": "MV6528EC61CFF47E0001D97DA6",
						"variationName": "SOFT BROWN (MUDA)",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833737113288536064",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:19:29Z",
				"externalUpdateDatetime": "2024-09-11T05:19:39Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:19:37Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:23:37Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E1285A46E0FB00011ED416",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911N8X9P684",
				"externalOrderSN": "240911N8X9P684",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 10825,
				"totalQuantity": 1,
				"customerName": "F***a",
				"customerMobile": "******05",
				"recipientName": "F***a",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E1285A46E0FB00011ED419",
						"externalOrderItemId": "240911N8X9P684-0",
						"externalVariationId": "5856624982",
						"externalStatus": null,
						"productName": "Pembersih Lensa Kacamata (Kama Lens Cleaner)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98o-lpei3gpk6jqy7d_tn",
						"quantity": 1,
						"actualPrice": 12500,
						"sku": "LNS-CLNR",
						"inventorySku": "LNS-CLNR",
						"inventoryId": "MV6528EC61CFF47E0001D97CF5",
						"variationName": null,
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833737075212652544",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:19:21Z",
				"externalUpdateDatetime": "2024-09-11T05:19:28Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:19:27Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:23:37Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E1284BC9E77C0001EBB0F1",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911N8WTF64S",
				"externalOrderSN": "240911N8WTF64S",
				"orderStatus": "SHIPPING",
				"externalOrderStatus": "TO_CONFIRM_RECEIVE",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 73523,
				"totalQuantity": 1,
				"customerName": "M******i",
				"customerMobile": "******02",
				"recipientName": "M******i",
				"logisticsProviderName": "SPX Instant",
				"logisticsTrackingNumber": "2222811593329152354",
				"deliveryType": null,
				"deliveryMethod": "PICK_UP",
				"dropShipperName": null,
				"labelPrintStatus": "PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "Ka mau di pasang kaca yang biasa ya jangan bolong ",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"SHIPPED",
					"STAY_PRINT",
					"SINGLE_PRODUCT_ONE",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP_SUCCESS"
				],
				"orderItems": [
					{
						"id": "OI66E1284BC9E77C0001EBB0F4",
						"externalOrderItemId": "240911N8WTF64S-0",
						"externalVariationId": "86145948339",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Sora (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98q-lpbp9kt0cjt9ac_tn",
						"quantity": 1,
						"actualPrice": 75900,
						"sku": "F-SORA-SB",
						"inventorySku": "F-SORA-SB",
						"inventoryId": "MV6528EC61CFF47E0001D97D90",
						"variationName": "SOFT BROWN",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Instant",
						"logisticsTrackingNumber": "2222811593329152354",
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833737012633653248",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "COMPLETE",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MARK_AS_FAKE_ORDER"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:19:06Z",
				"externalUpdateDatetime": "2024-09-11T06:16:37Z",
				"promiseShipBefore": "2024-09-11T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:19:14Z",
				"shippingDatetime": "2024-09-11T06:16:37Z",
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": "2024-09-11T05:31:27.444Z",
				"lastSyncDatetime": "2024-09-11T06:05:28Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E12839C9E77C00011C504C",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE61CC4CEDFD00016CF12F",
				"shopName": "Hey.Kama",
				"externalOrderId": "577205988650419871",
				"externalOrderSN": "577205988650419871",
				"orderStatus": "PAID",
				"externalOrderStatus": "AWAITING_SHIPMENT",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "TIKTOK_ID",
				"channelName": "TikTok",
				"paymentMethod": "COD",
				"orderType": "NORMAL",
				"fulfillBy": "FULFILLMENTBYSELLER",
				"sellerTotalAmount": 100900,
				"totalQuantity": 1,
				"customerName": "u***ra u***",
				"customerMobile": "+62 82317",
				"recipientName": "u***ra u***",
				"logisticsProviderName": "J&T Express",
				"logisticsTrackingNumber": "",
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"SINGLE_PRODUCT_ONE",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E12839C9E77C00011C504F",
						"externalOrderItemId": "577205988650419871-1729461652164085904",
						"externalVariationId": "1729461652164085904",
						"externalStatus": "AWAITING_SHIPMENT",
						"productName": "HeyKama - Frame Kacamata Anya ( Kacamata Cat Eye )",
						"productImage": "https://p16-oec-va.ibyteimg.com/tos-maliva-i-o3syd03w52-us/51e91bc6acd94497887a7f3f7675588a~tplv-o3syd03w52-origin-jpeg.jpeg?from=1413970683",
						"quantity": 1,
						"actualPrice": 99900,
						"sku": "F-ANYA-CR",
						"inventorySku": "F-ANYA-CR",
						"inventoryId": "MV6528EC61CFF47E0001D97DC8",
						"variationName": "CRYSTAL",
						"fulfillBy": "FULFILLMENT_BY_SELLER",
						"packageId": "1154836450476918431",
						"invoiceNumber": null,
						"logisticsProviderName": "J&T Express",
						"logisticsTrackingNumber": "",
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833736936364412928",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "7049609457587816194",
								"warehouseName": "ID Shipping Warehouse",
								"warehouseType": "CHANNEL",
								"warehouseCode": null
							},
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:18:45Z",
				"externalUpdateDatetime": "2024-09-11T05:18:47Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": null,
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:18:50Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E127EDC9E77C00011C451D",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911N8U1QXDY",
				"externalOrderSN": "240911N8U1QXDY",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "PRE_SALE",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 380130,
				"totalQuantity": 2,
				"customerName": "Y******h",
				"customerMobile": "******40",
				"recipientName": "Y******h",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "Minus kanan 5.75 Kiri 4.50 silinder 0.75",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E127EDC9E77C00011C4520",
						"externalOrderItemId": "240911N8U1QXDY-0",
						"externalVariationId": "86145948339",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Sora (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7r98q-lpbp9kt0cjt9ac_tn",
						"quantity": 1,
						"actualPrice": 75900,
						"sku": "F-SORA-SB",
						"inventorySku": "F-SORA-SB",
						"inventoryId": "MV6528EC61CFF47E0001D97D90",
						"variationName": "SOFT BROWN",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833736620336201728",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E127EDC9E77C00011C4521",
						"externalOrderItemId": "240911N8U1QXDY-1",
						"externalVariationId": "186889139017",
						"externalStatus": null,
						"productName": "heykama - Lensa Tipis / HI-Index ( SPH/CYL )",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qul7-ljcg3gmtte9644_tn",
						"quantity": 1,
						"actualPrice": 350000,
						"sku": "HI-CR-1.60",
						"inventorySku": "HI-CR-1.60",
						"inventoryId": "MV6528EC61CFF47E0001D97E52",
						"variationName": "Clear MAX CYL -200,Index 1.60",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833736620336201728",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:17:33Z",
				"externalUpdateDatetime": "2024-09-11T05:17:41Z",
				"promiseShipBefore": "2024-09-26T05:17:41Z",
				"payAtDatetime": "2024-09-11T05:17:39Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:23:37Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			},
			{
				"id": "SO66E127E34CEDFD000184C479",
				"merchantId": "MC62C5386E4CEDFD000145E333",
				"shopId": "SH62CE3E694CEDFD00016CF103",
				"shopName": "heykama",
				"externalOrderId": "240911N8TR6C24",
				"externalOrderSN": "240911N8TR6C24",
				"orderStatus": "PAID",
				"externalOrderStatus": "READY_TO_SHIP",
				"processStatus": null,
				"filterTags": null,
				"countryCode": "ID",
				"currencyCode": "IDR",
				"channelId": "SHOPEE_ID",
				"channelName": "Shopee",
				"paymentMethod": "PRE_PAID",
				"orderType": "NORMAL",
				"fulfillBy": "fulfilledByLocalSeller",
				"sellerTotalAmount": 151896,
				"totalQuantity": 2,
				"customerName": "A******h",
				"customerMobile": "******13",
				"recipientName": "A******h",
				"logisticsProviderName": "SPX Standard",
				"logisticsTrackingNumber": null,
				"deliveryType": null,
				"deliveryMethod": null,
				"dropShipperName": null,
				"labelPrintStatus": "NOT_PRINTED",
				"invoicePrintStatus": "NOT_PRINTED",
				"packingPrintStatus": "NOT_PRINTED",
				"pickingPrintStatus": "NOT_PRINTED",
				"noteByBuyer": "Kanan -1 kiri -1",
				"noteBySeller": null,
				"pickingNote": null,
				"subLogisticAble": true,
				"orderStatusConsistent": true,
				"isMoreOutOrder": false,
				"advancePackage": null,
				"modifyWarehouseStatus": null,
				"fulfillmentStatus": null,
				"bookingSn": null,
				"matchStatus": null,
				"orderTags": [
					"OWN_WAREHOUSE",
					"PENDING",
					"PENDING_LIST",
					"MULTI_PRODUCT",
					"ORDER_SHIP_WAIT",
					"WAREHOUSE_TYPE_LOCAL",
					"ORDER_SHIP"
				],
				"orderItems": [
					{
						"id": "OI66E127E34CEDFD000184C47C",
						"externalOrderItemId": "240911N8TR6C24-0",
						"externalVariationId": "15001780827",
						"externalStatus": null,
						"productName": "heykama - Lensa Minus{{$t('Clear')}}(SPH/CYL) CR",
						"productImage": "https://cf.shopee.co.id/file/1b717e56a47bf52bd9b31187d97de2b7_tn",
						"quantity": 1,
						"actualPrice": 85500,
						"sku": "LNS-CR-MIN-0.25",
						"inventorySku": "LNS-CR-MIN-0.25",
						"inventoryId": "MV6528EC61CFF47E0001D97D01",
						"variationName": "CLEAR,MIN -0.25 s/d -3.00",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833736575478099968",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					},
					{
						"id": "OI66E127E34CEDFD000184C47D",
						"externalOrderItemId": "240911N8TR6C24-1",
						"externalVariationId": "170462447674",
						"externalStatus": null,
						"productName": "heykama - Frame Kacamata Sora (Cat Eye)",
						"productImage": "https://cf.shopee.co.id/file/id-11134207-7qukx-lgiunfrvj01p47_tn",
						"quantity": 1,
						"actualPrice": 89900,
						"sku": "F-SORA-M",
						"inventorySku": "F-SORA-M",
						"inventoryId": "MV6528EC61CFF47E0001D97D91",
						"variationName": "MIST",
						"fulfillBy": "fulfilled_by_local_seller",
						"packageId": null,
						"invoiceNumber": null,
						"logisticsProviderName": "SPX Standard",
						"logisticsTrackingNumber": null,
						"orderFlag": null,
						"productType": null,
						"pickingStatusId": null,
						"pickingStatus": null,
						"orderScanFlag": null,
						"buyerNote": null,
						"outboundOrderNo": "So-1833736575478099968",
						"failureType": null,
						"outOrderFailureReason": null,
						"pushStatus": "NORMAL",
						"outOrderStatus": "PENDING",
						"isReturnStock": "0",
						"warehouseType": "MY_WAREHOUSE",
						"localWarehouseId": "WW62C538AB5908010001852B1A",
						"localWarehouseName": "DefaultWarehouse",
						"warehouseInfos": [
							{
								"warehouseId": "WW62C538AB5908010001852B1A",
								"warehouseName": "DefaultWarehouse",
								"warehouseType": "OWN_WAREHOUSE",
								"warehouseCode": null
							}
						]
					}
				],
				"refundItems": null,
				"operationTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER"
				],
				"operationTagTypes": [
					"SYNC_ORDER",
					"CANCEL_ORDER",
					"SHIP_ORDER",
					"PRINT_INVOICE",
					"PRINT_PACKING",
					"PRINT_PICKING",
					"VIEW_OPERATION_RECORD",
					"MODIFY_WAREHOUSE",
					"MODIFY_PRODUCT",
					"MODIFY_GIVEAWAY",
					"MARK_AS_FAKE_ORDER",
					"SHELVE"
				],
				"tagErrorMessage": null,
				"externalCreateDatetime": "2024-09-11T05:17:22Z",
				"externalUpdateDatetime": "2024-09-11T05:50:37Z",
				"promiseShipBefore": "2024-09-12T16:59:59Z",
				"payAtDatetime": "2024-09-11T05:50:35Z",
				"shippingDatetime": null,
				"completeDatetime": null,
				"cancelAtDatetime": null,
				"labelPrintTime": null,
				"lastSyncDatetime": "2024-09-11T05:54:03Z",
				"posOrderStatus": null,
				"settlementStatus": null,
				"settlementTimes": null,
				"settlementNumbers": null
			}
		],
		"number": 0,
		"size": 50,
		"totalElements": 20707,
		"sort": [],
		"pageable": {
			"sort": [],
			"pageNumber": 0,
			"pageSize": 50,
			"offset": 0,
			"paged": true,
			"unpaged": false
		},
		"last": false,
		"totalPages": 415,
		"first": true,
		"numberOfElements": 50,
		"empty": false
	},
	"extra": null,
	"transactionId": "5d0d467203fcd2bc"
}
    };
  },
  computed: {
    isKreditAkun() {
      return this.transactionType == 2;
    },
    isDebitAkun() {
      return this.transactionType == 1;
    },
    totalPenjualan() {
      if (!this.items || this.items.length < 1) {
        return 0;
      }

      return this.items.reduce(
        (total, item) => (total += parseInt(item.uang_masuk)),
        0
      );
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    apiFile() {
      return "https://api-heykama.notive.my.id/";
    },
  },
  watch: {
    truncateString(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + '...';
  } else {
    return str;
  }
},
    transactionType(val) {
      if (val) {
        if (val == 1) {
          this.id_akun = this.debitAkun;
        } else {
          this.id_akun = this.kreditAkun;
        }
      }
    },
    perPage(value) {
      if (value) {
        this.getTransaksi();
      }
    },
    // filter(keyword) {
    //  if (keyword.length > 2 || keyword.length == 0) {
    //    this.getTransaksi();
    // }
    //},
    currentPage(val) {
      if (val) {
        this.getTransaksi();
        // this.items
      }
    },
  },
  methods: {
    submitkonfirmasi2() {
      // this.$refs.vkonfirmasi.validate().then((success) => {
      //   if (success) {
      this.label = "Loading...";
      let payload2 = {
        no_invoice: this.formkonfirmasi.no_invoice,
      };
      this.$store
        .dispatch("transaksiOnline/tandaiLunas", payload2)
        .then(() => {
          this.showKonfirmasi = false;
          this.displaySuccess({
            text: "Berhasil dikonfirmasi",
          });
          this.getTransaksi();
          // this.formKonfirmasi = null
        })
        .catch((e) => {
          this.label = "Submit";
          this.displayError(e);
          return false;
        });
      //   }
      // });
    },
    submitkonfirmasi() {
      // this.$refs.vkonfirmasi.validate().then((success) => {
      //   if (success) {
      this.label = "Loading...";
      // let join = this.pilihteritori;
      // let string = join.join(",");
      // var array = JSON.parse("[" + string + "]");
      let payload = {
        no_invoice: this.formkonfirmasi.no_invoice,
        // nama_cabang: this.form.nama_cabang,
        akun_id: this.formkonfirmasi.id_akun,
        kas_id: this.formkonfirmasi.id_kas,
      };
      let payload2 = {
        no_invoice: this.formkonfirmasi.no_invoice,
      };
      this.$store
        .dispatch("transaksiOnline/tandaiLunas", payload2)
        .then(() => {
          this.showKonfirmasi = false;
          this.displaySuccess({
            text: "Berhasil dikonfirmasi",
          });
          this.getTransaksi();
          // this.formKonfirmasi = null
        })
        .catch((e) => {
          this.label = "Submit";
          this.displayError(e);
          return false;
        });
      //   }
      // });
    },
    aksiKonfirmasiawal(item) {
      this.konfirmasi = item.id;
      this.formkonfirmasi = {
        no_invoice: item.no_invoice ? item.no_invoice : "-",
        bukti_tf: {
          file: item.bukti_tf ? item.bukti_tf.file : null,
        },
      };
      // this.submitkonfirmasi2()
      // console.log('AJI', this.foto)
      this.getTransaksiId();
      this.showKonfirmasi = true;
    },
    aksiKonfirmasipg(item) {
      // this.konfirmasi = item.id;
      // this.formkonfirmasi = {
      //   no_invoice: item.no_invoice ? item.no_invoice : "-",
      //   bukti_tf: {
      //     file: item.bukti_tf ? item.bukti_tf.file : null,
      //   },
      // };
      let payload = {
        no_invoice: item.no_invoice ? item.no_invoice : "-",
      };
      // this.$swal({
      //   title: "Anda yakin?",
      //   text: `Invoice : ${terapis.nama_lengkap} ini ditandai lunas?`,
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonText: "Ya",
      //   cancelButtonText: "Batal",
      //   customClass: {
      //     confirmButton: "btn btn-success",
      //     cancelButton: "btn btn-danger ml-1",
      //   },
      //   buttonsStyling: false,
      // }).then((result) => {
      //   if (result.value) {
      this.$store
        .dispatch("transaksiOnline/getDatapg", payload)
        .then((res) => {
          // this.formkonfirmasi = res;
          this.getTransaksi();
        })
        .catch((err) => {
          this.displayError(err);
          return false;
        });
      //   }
      // });
    },
    aksiKonfirmasi(item) {
      this.konfirmasi = item.id;
      this.formkonfirmasi = {
        no_invoice: item.no_invoice ? item.no_invoice : "-",
        bukti_tf: {
          file: item.bukti_tf ? item.bukti_tf.file : null,
        },
      };
      this.submitkonfirmasi2();
      // console.log('AJI', this.foto)
      // this.getTransaksiId();
      // this.showKonfirmasi = true;
    },
    getTransaksiId(item) {
      this.loadingfoto = true;
      let params = {
        id: this.konfirmasi,
        show_bukti_tf: 1,
        view_as_invoice: 1,
      };
      this.$store
        .dispatch("transaksiOnline/getDataById", params)
        .then((res) => {
          // let items = JSON.parse(JSON.stringify(this.$store.state.transaksiOnline.datas));
          // let items_total = this.$store.state.transaksiOnline.totals;
          this.formkonfirmasi = res;
          // this.totalRows = items_total;
          this.loadingfoto = false;
          // this.items = res;
        })
        .catch((err) => {
          // console.error(err);
          this.loadingfoto = false;
          this.displayError(err);
          return false;
        });
    },
    async exportSales({ tgl_awal, tgl_akhir, type_export }) {
      if (!tgl_awal || !tgl_akhir) {
        this.displayError({
          message: "Harap isi tanggal awal dan akhir!",
        });
        return false;
      }

      // validate type_export pdf || excel
      const params = {
        tgl_awal,
        tgl_akhir,
        type_export,
      };
      if (this.isSales && this.myGudang)
        params.sales_id =
          this.user && this.user.karyawan ? this.user.karyawan.id : null;
      if (this.dataExport.konsumen_id)
        params.konsumen_id = this.dataExport.konsumen_id;
      if (this.dataExport.sales_id) params.sales_id = this.dataExport.sales_id;

      try {
        this.loading = true;
        const response = await this.printSales(params);
        this.loading = false;
        if (type_export == "pdf") {
          window.open(URL.createObjectURL(response));
        } else {
          await window.open(response);
          setTimeout(async () => {
            // delete selected file
            const arrFile = response.split("/");
            const filename = arrFile[arrFile.length - 1];

            await this.clearExportedFile(filename);
          }, 1000);
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },

    getTransaksi() {
      this.loading = true;
      // this.refreshToken()
      if (!this.isCabang && !this.isTerapis) {
        const perPage = parseInt(this.perPage);
        const currentPage = (parseInt(this.currentPage) - 1) * perPage;
        var params = {
          search: this.filter != null ? this.filter : null,
          // order: "desc",
          start: currentPage,
          length: this.perPage,
          // metode_bayar: "manual_transfer",
          show_bukti_tf: 1,
          status_bayar: this.status_bayar != null ? this.status_bayar : null,
          status: this.statuskirim != null ? this.statuskirim : null,
          member_id: this.is_member != null ? this.is_member : null,
          is_accounted: this.is_accounted != null ? this.is_accounted : null,
        };
      } else if (this.isTerapis) {
        const perPage = parseInt(this.perPage);
        const currentPage = (parseInt(this.currentPage) - 1) * perPage;
        var params = {
          search: this.filter != null ? this.filter : null,
          terapis: this.user.id,
          // order: "desc",
          start: currentPage,
          length: this.perPage,
          status_bayar: this.status_bayar != null ? this.status_bayar : null,
          status: this.statuskirim != null ? this.statuskirim : null,
          type_transaction:
            this.typeTransaksi != null ? this.typeTransaksi : null,
          // metode_bayar: "manual_transfer",
        };
      } else {
        const perPage = parseInt(this.perPage);
        const currentPage = (parseInt(this.currentPage) - 1) * perPage;
        var params = {
          search: this.filter != null ? this.filter : null,
          member_id: this.user.member_id,
          // order: "desc",
          start: currentPage,
          length: this.perPage,
          status_bayar: this.status_bayar != null ? this.status_bayar : null,
          status: this.statuskirim != null ? this.statuskirim : null,
          type_transaction:
            this.typeTransaksi != null ? this.typeTransaksi : null,
          // metode_bayar: "manual_transfer",
        };
      }
      this.$store
        .dispatch("transaksiOnline/getData", params)
        .then((res) => {
          let items = JSON.parse(
            JSON.stringify(this.$store.state.transaksiOnline.datas)
          );
          let items_total = this.$store.state.transaksiOnline.totals;
          let items_masuk = this.$store.state.transaksiOnline.masuks;
          this.items = items;
          this.totalRows = items_total;
          this.masuk = items_masuk;
          this.loading = false;
          // this.items = res;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.displayError(err);
          return false;
        });
    },
    getKas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let hem = JSON.parse(JSON.stringify(this.$store.state.kas.datas));
          hem.map((item) => {
            item.value = item.id;
            item.text =
              item.saldo >= 0
                ? item.nama + " -> " + this.formatRupiah(item.saldo)
                : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
          });
          this.id_kas = hem;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getAkun() {
      this.$store
        .dispatch("akun/getData", {})
        .then(() => {
          let akuns = JSON.parse(JSON.stringify(this.$store.state.akun.datas));
          akuns.map((item) => {
            item.value = item.id;
            item.text =
              item.saldo >= 0
                ? item.nama + " -> " + this.formatRupiah(item.saldo)
                : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
          });

          this.debitAkun = akuns.filter((akun) =>
            [this.JENIS_AKUN.debit, this.JENIS_AKUN.debit_kredit].includes(
              akun.jenis
            )
          );
          this.kreditAkun = akuns.filter((akun) =>
            [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(
              akun.jenis
            )
          );
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getMember() {
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        sebagai: "cabang",
        // order: "desc",
        // start: currentPage,
        // length: this.perPage,
        // filter_by: "nama_lengkap",
        // category_paket_id: this.filterKatPaket != null ? this.filterKatPaket.id : null,
        // paket_id: this.filterPaket != null ? this.filterPaket.id : null,
        // jadwal_id: this.filterJadwal != null ? this.filterJadwal.id : null,
        // user_id: this.user.id,
      };
      this.$store.dispatch("member/getData", payload).then(() => {
        let items = JSON.parse(JSON.stringify(this.$store.state.member.datas));
        items.map((item) => {
          item.value = item.id;
          item.text = item.nama_lengkap;
        });
        this.optis_member = items;
        // this.items = this.$store.state.member.datas;
        // this.totalRows = this.items.length;
      });
    },
    getTerapis() {
      this.$store
        .dispatch("terapis/getData")
        .then((res) => {
          this.listTerapis = res;
        })
        .catch((error) => {
          console.error("Error fetching terapis data:", error);
        });
    },
    modalTerapis(item) {
      this.itemTransaksiTerapis = item;
      this.showModalTerapis = true;
    },
    pilihTerapis(terapis) {
      this.$swal({
        title: "Anda yakin?",
        text: `Apakah yakin untuk memilih ${terapis.nama_lengkap}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loadingTerapis = true;
          let payload = {
            transaksi_id: this.itemTransaksiTerapis.id,
            karyawan_id: terapis.id,
          };
          this.$store
            .dispatch("transaksiOnline/saveTerapis", payload)
            .then(() => {
              this.displaySuccess({
                text: "Terapis Berhasil dipilih",
              });
              this.loadingTerapis = false;
              this.showModalTerapis = false;
              this.itemTransaksiTerapis = {};
              this.getTransaksi();
              this.getTerapis();
            })
            .catch((e) => {
              this.loadingTerapis = false;
              this.showModalTerapis = false;
              this.itemTransaksiTerapis = {};
              this.displayError(e);
              return false;
            });
        }
      });
    },
  },
  created() {
    // this.getMember();
    // this.getTransaksi();
    // this.getKas();
    // this.getAkun();
    // this.getTerapis();
    if (!this.isFinance) {
      this.fields = [
        {
          key: "no",
          label: "No",
        },
        { key: "waktu_transaksi", label: "Tanggal Transaksi", sortable: true },
        { key: "no_invoice", label: "No. Invoice", sortable: true },
        { key: "member_id", label: "Affiliator" },
        { key: "nama", label: "Konsumen" },
        { key: "total_bayar", label: "Nominal" },
        { key: "transaction_type", label: "Tipe Transaksi" },
        { key: "status_bayar", label: "Status Bayar" },
        { key: "status", label: "Status Pengiriman" },
        { key: "action", label: "#" },
      ];
    } else if (this.isFinance) {
      this.fields = [
        { key: "actionFinance", label: "#", stickyColumn: true },
        {
          key: "no",
          label: "No",
        },
        { key: "waktu_transaksi", label: "Tanggal Transaksi", sortable: true },
        { key: "no_invoice", label: "No. Invoice", sortable: true },
        { key: "member_id", label: "Affiliator" },
        // { key: "nama", label: "Konsumen" },
        { key: "status_bayar", label: "Status Bayar" },
        { key: "status", label: "Status Pengiriman" },
        { key: "uang_masuk", label: "Uang Masuk" },
        { key: "total_bayar", label: "Total Bayar" },
        { key: "transaction_type", label: "Tipe Transaksi" },
        { key: "payment_option", label: "Metode Pembayaran" },
      ];
    }

    // this.getData();
  },
};
</script>
